<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <b-row>
              <b-col md="3">
                <b-form-group label="Bulan" label-cols-md="3">
                  <b-form-select
                    :options="periode"
                    type="text"
                    @change="getDatas()"
                    v-model="data.bulan"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Tahun" label-cols-md="3">
                  <b-form-select
                    :options="thn"
                    type="text"
                    @change="getDatas()"
                    v-model="data.tahun"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Status" label-cols-md="3">
                  <b-form-select
                    :options="options"
                    type="text"
                    @change="getDatas()"
                    v-model="data.statusPerjanjianAktif"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-button block variant="danger" @click="reset()"
                  >Reset</b-button
                >
              </b-col>
              <b-col md="2">
                <b-button block variant="success" @click="rekap()"
                  >Cetak List</b-button
                >
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6" offset-md="6">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model.lazy="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      @click="editData(item.item)"
                      ><CIcon name="cil-pencil" />{{ item.actions }}</b-button
                    >

                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail'"
                      v-b-modal.modal-detail
                      @click="(data = item.item), (detail = !detail)"
                      ><CIcon name="cil-info" />{{ item.actions }}</b-button
                    >

                    <!-- <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus / Pecat Karyawan'"
                      v-b-modal.modal-delete
                      @click="data = item.item"
                      ><CIcon name="cil-trash" />{{ item.actions }}</b-button
                    > -->
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-row>
                  <b-col> Total Data {{ totalRows }}</b-col></b-row
                >
              </b-col>
              <b-col md="5" offset-md="4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- <modal-edit
      :data="data"
      :edit="edit"
      :asuransi="asuransi"
      @alertFromChild="triggerAlert($event)"
    /> -->
    <!-- <modal-detail :data="data" :detail="detail" />
    <modal-delete
      :data="data"
      :pecat="pecat"
      @alertFromChild="triggerAlert($event)"
    />
    <modal-keluarga />

    <modal-data-karyawan
      :data="data"
      :edit="edit"
      :asuransi="asuransi"
      @alertFromChild="triggerAlert($event)"
    /> -->
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
// import modalKeluarga from "../../../component/hcms/karyawan/modalKeluarga.vue";
import moment from "moment";
moment.locale("id");
// import modalDetail from "../../../component/hcms/karyawan/modalDetail.vue";
// import modalDelete from "../../../component/hcms/karyawan/modalDelete.vue";
// import modalDataKaryawan from "../../../component/hcms/karyawan/modalDataKaryawanBelum.vue";
export default {
  components: {
    // modalDetail,
    // modalDelete,
    // modalKeluarga,
    // modalDataKaryawan,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      asuransi: [],
      msg: "",
      edit: false,
      detail: false,
      pecat: [],
      items: [],
      thn: [],
      data: {
        bulan: null,
        tahun: null,
        statusPerjanjianAktif: null,
      },
      periode: [
        { value: "01", text: "Januari" },
        { value: "02", text: "Februari" },
        { value: "03", text: "Maret" },
        { value: "04", text: "April" },
        { value: "05", text: "Mei" },
        { value: "06", text: "Juni" },
        { value: "07", text: "Juli" },
        { value: "08", text: "Agustus" },
        { value: "09", text: "September" },
        { value: "10", text: "Oktober" },
        { value: "11", text: "November" },
        { value: "12", text: "Desember" },
      ],
      options: [
        { value: 1, text: "Selesai /Habis Kontrak" },
        { value: 2, text: "Diberhentikan" },
        { value: 3, text: "Resign" },
        { value: 6, text: "Meninggal" },
        { value: 7, text: "Hilang / Tanpa Keterangan" },
        { value: 9, text: "Pensiun" },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatKaryawan",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaDivisi",
          label: "Divisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaPosisi",
          label: "Posisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-6 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["namaKaryawan"],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
    this.getoptions();
  },
  methods: {
    editData(data) {
      let vm = this;
      vm.data = data;
      vm.edit = !vm.edit;

      if (vm.data != "") {
        vm.$bvModal.show("modal-data-karyawan-belum");
      }
    },
    getoptions() {
      for (let i = 2000; i < 2050; i++) {
        this.thn.push(i);
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    reset() {
      this.data = {
        bulan: null,
        tahun: null,
        statusPerjanjianAktif: null,
      };
    },
    async getDatas() {
      let vm = this;
      this.tableBusy = true;
      let listKaryawan = await axios.post(
        ipBackend + "dataKaryawan/listDataKaryawanTidakAktif",
        vm.data
      );
      this.items = listKaryawan.data.data;
      for (let i = 0; i < this.items.length; i++) {
        let x = this.items[i];
        x.no = i + 1;
        x.tglLahir = moment(x.tanggalLahirKaryawan).format("LL");
        if (!x.tanggalMulaiKerja) {
          x.tglMulai = "-";
        } else {
          x.tglMulai = moment(x.tanggalMulaiKerja).format("LL");
        }
        for (let l = 0; l < vm.options; l++) {
          // console.log(x.statusPerjanjianAktif, vm.options[l]);
          if (x.statusPerjanjianAktif == vm.options[l]) {
            x.namaStatus = vm.options[l].text;
          }
        }
      }
      // console.log(this.items, "ini item");
      this.totalRows = this.items.length;
      this.tableBusy = false;
    },

    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getDatas();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-edit-pelanggaran"
      size="lg"
      centered
      title="Edit Data Pelanggaran"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Tanggal" label-cols-md="3">
          <date-picker
            style="width: 100%"
            v-model="dataPelanggaran.tanggalPelanggaran"
            :placeholder="dataPelanggaran.tanggal"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Jenis Pelanggaran" label-cols-md="3">
          <multiselect
            :state="checkIfValid('masterPelanggaranIdEdit')"
            v-model="$v.dataPelanggaran.masterPelanggaranIdEdit.$model"
            :options="pelanggaran"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaPelanggaran"
            @input="itikiwir(dataPelanggaran.masterPelanggaranIdEdit)"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Sumber Pelanggaran" label-cols-md="3">
          <b-form-select
            :options="sumber"
            :state="checkIfValid('jenisSumberPelanggaran')"
            v-model="$v.dataPelanggaran.jenisSumberPelanggaran.$model"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Keterangan Pelanggaran" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('keteranganPelanggaran')"
            v-model="$v.dataPelanggaran.keteranganPelanggaran.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-pelanggaran')"
        >
          {{ $store.state.form.batal }}
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
    Multiselect,
  },
  name: "modalEdit",
  props: ["dataPelanggaran", "pelanggaran", "edit"],
  data() {
    return {
      sumber: [
        { value: "Internal", text: "Internal" },
        { value: "Eksternal", text: "Eksternal" },
      ],
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataPelanggaran, null, 4);
    },
    isValid() {
      return !this.$v.dataPelanggaran.$invalid;
    },
    isDirty() {
      return this.$v.dataPelanggaran.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataPelanggaran: {
      jenisSumberPelanggaran: {
        required,
      },
      keteranganPelanggaran: {
        required,
      },
      masterPelanggaranIdEdit: {
        required,
      },
    },
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        for (let i = 0; i < this.pelanggaran.length; i++) {
          if (
            this.pelanggaran[i].id == this.dataPelanggaran.masterPelanggaranId
          ) {
            this.dataPelanggaran.masterPelanggaranIdEdit = this.pelanggaran[i];
          }
        }
        console.log(
          this.dataPelanggaran.masterPelanggaranIdEdit,
          "ini editnya"
        );
        // console.log(this.pelanggaran, "ini pelanggaran");
      }
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataPelanggaran[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      // console.log(x);
    },

    simpan() {
      let vm = this;
      // console.log(vm.data, "ini data simpan");
      vm.dataPelanggaran.masterPelanggaranId =
        vm.dataPelanggaran.masterPelanggaranIdEdit.id;
      vm.dataPelanggaran.tanggalPelanggaran = moment(
        vm.dataPelanggaran.tanggalPelanggaran
      );
      console.log(vm.dataPelanggaran, "ini data pelanggaran");
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        axios
          .post(ipBackend + "poolPelanggaran/update", vm.dataPelanggaran)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH DATA PELANGGARAN",
                showing: true,
              });
              this.$bvModal.hide("modal-edit-pelanggaran");
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-input-kerja"
      size="lg"
      centered
      title="Tambah Data Pengalaman Kerja"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Perusahaan" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="dataPengalaman.namaPerusahaan"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Jabatan" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="dataPengalaman.jabatan"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Tahun" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="dataPengalaman.tahun"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="primary"
          :disabled="busy"
          @click="simpanPengalaman()"
          >{{ button }}</b-button
        >
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-kerja')"
        >
          {{ $store.state.form.batal }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  props: ["data"],
  data() {
    return {
      button: "Simpan",
      busy: false,
      dataPengalaman: {
        namaPerusahaan: "",
        tahun: "",
        jabatan: "",
      },
    };
  },
  methods: {
    simpanPengalaman() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.dataPengalaman.dataKaryawanId = vm.data.dataKaryawanId;
      // console.log(vm.dataPengalaman, "data pengalaman");
      axios
        .post(
          ipBackend + "dataKaryawan/tambahPengalamanKerja",
          vm.dataPengalaman
        )
        .then((res) => {
          console.log(res, "ini res");
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN PENGALAMAN KERJA",
              showing: true,
            });
            vm.reset();
            vm.$bvModal.hide("modal-input-kerja");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          vm.$bvModal.hide("modal-input-kerja");
        });
    },
    reset() {
      let vm = this;

      vm.dataPengalaman.jabatan = "";
      vm.dataPengalaman.namaPerusahaan = "";
      vm.dataPengalaman.tahun = "";
    },
  },
};
</script>

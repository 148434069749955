<template>
  <div>
    <b-modal
      id="modal-print"
      size="lg"
      centered
      scrollable
      title="Cetak Surat Surat"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-row>
        <b-col cols="12" md="4" lg="4">
          <b-row class="mt-2 mb-2">
            <b-col
              ><b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('suketAktif.docx'),
                    (gen = !gen),
                    (name = 'Surat Keterangan karyawan.docx')
                "
              >
                Print Surat Keterangan
              </b-button></b-col
            >
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col
              ><b-button
                class="mr-1"
                variant="secondary"
                block
                @click="seturl('CoverDataDiri.docx'), (gen = !gen), (name = 'Data Diri Karyawan.docx')"
              >
                Print Surat Data Diri
              </b-button></b-col
            >
          </b-row>
          <!-- <b-row class="mt-2 mb-2">
            <b-col
              ><b-button
                class="mr-1"
                variant="secondary"
                block
                @click="seturl(), (gen = !gen), (name = '')"
              >
                Print Surat Konfirmasi
              </b-button></b-col
            >
          </b-row> -->
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          class="mr-1"
          variant="secondary"
          @click="$bvModal.hide('modal-print')"
        >
          Batal
        </b-button>
      </template>
    </b-modal>
    <generator :data="printData" :gen="gen" :url="url" :name="name" />
  </div>
</template>

<script>
import { ipBackend } from "@/ipBackend";
import generator from "@/component/DOCX/docx_generator.vue";
export default {
  components: {
    generator,
  },
  name: "modalDetail",
  props: ["printData"],
  data() {
    return {
      ipBackend,
      gen: false,
      name: "",
      url: "",
    };
  },
  methods: {
    seturl(x) {
      console.log(this.printData);
      this.url = this.ipBackend + "asset/docx/" + x;
      //   console.log(this.url);
    },
  },
};
</script>

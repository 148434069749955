<template>
  <div>
    <b-modal
      id="modal-keluarga"
      size="lg"
      centered
      scrollable
      title="Data Keluarga"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-form>
            <b-form-group label="Nama" label-cols-md="3">
              <b-form-input type="text"></b-form-input>
            </b-form-group>

            <b-form-group label="No. KTP" label-cols-md="3">
              <b-form-input type="text"></b-form-input>
            </b-form-group>

            <b-form-group label="Agama" label-cols-md="3">
              <b-form-select :options="agama"></b-form-select>
            </b-form-group>

            <b-form-group label="Kebangsaan" label-cols-md="3">
              <b-form-select :options="kebangsaan"></b-form-select>
            </b-form-group>

            <b-form-group label="Jenis Kelamin" label-cols-md="3">
              <b-form-select :options="jenisKelamin"></b-form-select>
            </b-form-group>

            <b-form-group label="Tempat Lahir" label-cols-md="3">
              <b-form-input type="text"></b-form-input>
            </b-form-group>

            <b-form-group label="Tanggal Lahir" label-cols-md="3">
              <date-picker style="width: 100%"></date-picker>
            </b-form-group>

            <b-form-group label="Email" label-cols-md="3">
              <b-form-input type="text"></b-form-input>
            </b-form-group>

            <b-form-group label="Alamat" label-cols-md="3">
              <b-form-textarea type="text"></b-form-textarea>
            </b-form-group>

            <b-form-group label="Status Dalam Keluarga" label-cols-md="3">
              <b-form-input type="text"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-md="3">
              <b-button variant="primary">Tambahkan</b-button>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <hr />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Per Halaman"
                label-for="per-page-select"
                label-cols-md="7"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="md"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6" offset-md="3">
              <b-form-group
                label="Cari"
                label-for="filter-input"
                label-cols-md="3"
                label-align-md="right"
                label-size="md"
              >
                <b-input-group size="md">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Ketik disini untuk mencari ..."
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                      variant="danger"
                      >Hapus</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                stacked="md"
                responsive
                show-empty
                small
                @filtered="onFiltered"
                bordered
                striped
                hover
              >
                <template #cell(actions)="item">
                  <b-button
                    variant="danger"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Hapus Data'"
                    v-b-modal.modal-delete-keluarga
                    ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                  >
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="5" offset-md="7">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-keluarga')">
          Batal
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-delete-keluarga"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menghapus data keluarga ini ?</p>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-delete-keluarga')"
        >
          Tidak
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      kebangsaan: [
        { value: "WNI", text: "WNI" },
        { value: "WNA", text: "WNA" },
      ],
      agama: [
        { value: "Islam", text: "Islam" },
        { value: "Katholik", text: "Katholik" },
        { value: "Protestan", text: "Protestan" },
        { value: "Hindu", text: "Hindu" },
        { value: "Budha", text: "Budha" },
        { value: "Khonghucu", text: "Khonghucu" },
      ],
      jenisKelamin: [
        { value: "Laki-laki", text: "Laki-laki" },
        { value: "Perempuan", text: "Perempuan" },
      ],
      items: [
        {
          no: "-",
          no: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortable: true,
          sortDirection: "desc",
          class: "table-number text-center",
        },
        {
          key: "nama",
          label: "nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenisKelamin",
          label: "Jenis Kelamin",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamat",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusKeluarga",
          label: "Status Dalam Keluarga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-1 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<template>
  <div>
    <b-modal
      id="modal-input-pelanggaran"
      size="lg"
      centered
      title="Tambah Data Pelanggaran"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Tanggal" label-cols-md="3">
          <date-picker
            style="width: 100%"
            :state="checkIfValid('tanggalPelanggaran')"
            v-model="$v.dataPelanggaran.tanggalPelanggaran.$model"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Jenis Pelanggaran" label-cols-md="3">
          <multiselect
            :state="checkIfValid('masterPelanggaranId')"
            v-model="$v.dataPelanggaran.masterPelanggaranId.$model"
            :options="pelanggaran"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaPelanggaran"
            @input="itikiwir(dataPelanggaran.masterPelanggaranId)"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Sumber Pelanggaran" label-cols-md="3">
          <b-form-select
            :options="sumber"
            :state="checkIfValid('jenisSumberPelanggaran')"
            v-model="$v.dataPelanggaran.jenisSumberPelanggaran.$model"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Keterangan Pelanggaran" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('keteranganPelanggaran')"
            v-model="$v.dataPelanggaran.keteranganPelanggaran.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-pelanggaran')"
        >
          {{ $store.state.form.batal }}
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
    Multiselect,
  },
  name: "modalInput",
  props: ["data", "pelanggaran"],
  data() {
    return {
      dataPelanggaran: {
        masterPelanggaranId: "",
        jenisSumberPelanggaran: "",
        tanggalPelanggaran: "",
        keteranganPelanggaran: "",
      },
      sumber: [
        { value: "Internal", text: "Internal" },
        { value: "Eksternal", text: "Eksternal" },
      ],
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataPelanggaran, null, 4);
    },
    isValid() {
      return !this.$v.dataPelanggaran.$invalid;
    },
    isDirty() {
      return this.$v.dataPelanggaran.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataPelanggaran: {
      tanggalPelanggaran: {
        required,
      },
      jenisSumberPelanggaran: {
        required,
      },
      keteranganPelanggaran: {
        required,
      },
      masterPelanggaranId: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataPelanggaran[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      // console.log(x);
    },

    simpan() {
      let vm = this;
      // console.log(vm.data, "ini data simpan");
      vm.dataPelanggaran.masterPelanggaranId =
        vm.dataPelanggaran.masterPelanggaranId.id;
      vm.dataPelanggaran.dataKaryawanId = vm.data.dataKaryawanId;
      vm.dataPelanggaran.tanggalPelanggaran = moment(
        vm.dataPelanggaran.tanggalPelanggaran
      );
      // console.log(vm.dataPelanggaran, "ini data pelanggaran");
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        axios
          .post(ipBackend + "poolPelanggaran/register", vm.dataPelanggaran)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN DATA PELANGGARAN",
                showing: true,
              });
              this.dataPelanggaran.masterPelanggaranId = "";
              this.dataPelanggaran.tanggalPelanggaran = "";
              this.dataPelanggaran.jenisSumberPelanggaran = "";
              this.dataPelanggaran.keteranganPelanggaran = "";
              this.$v.$reset()
              this.$bvModal.hide("modal-input-pelanggaran");
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
  },
};
</script>

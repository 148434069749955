<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <h4><strong>Data Absensi Kedatangan</strong></h4>
        <hr />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col md="3">
        <b-form-group
          :label="$store.state.table.perHalaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          :label="$store.state.table.cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$store.state.table.cariHolder"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >{{ $store.state.table.hapusCari }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(statusIjin)="item">
            <p v-if="item.item.statusIjin == 0">Berangkat</p>
            <p v-if="item.item.statusIjin == 1">Cuti</p>
            <p v-if="item.item.statusIjin == 2">Dinas</p>
            <p v-if="item.item.statusIjin == 3">Cuti Hamil</p>
            <p v-if="item.item.statusIjin == 4">Ijin</p>
            <p v-if="item.item.statusIjin == 5">Sakit</p>
            <p v-if="item.item.statusIjin == 6">Alpha/Mangkir</p>
          </template>
          <template #cell(createdAt)="item">
            <div v-if="item.item.statusIjin == 0">
              {{ moment(item.item.createdAt).format("ll") }}
            </div>
            <div v-else>{{ moment(item.item.tglIjin).format("ll") }}</div>
          </template>
          <template #cell(actions)="item">
            <b-button
              v-b-modal.modal-foto
              @click="
                (src1 = ipBackend + 'asset/file/' + item.item.fotoAbsensi),
                  (src2 =
                    ipBackend + 'asset/file/' + item.item.fotoAbsensiKeluar)
              "
              >Foto</b-button
            >
            {{ item.actions }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
    <div>
      <b-modal
        id="modal-foto"
        size="lg"
        centered
        scrollable
        title="Foto Absensi Karyawan"
        header-bg-variant="success"
        header-text-variant="light"
      >
        <div class="d-flex justify-content-between">
          <div>
            <div><span>Foto Masuk</span></div>
            <div>
              <img
                :src="src1"
                alt="foto absen masuk"
                style="width: 300px; height: 300px"
              />
            </div>
          </div>
          <div>
            <div><span>Foto Pulang</span></div>
            <div>
              <img
                :src="src2"
                alt="foto absen pulang"
                style="width: 300px; height: 300px"
              />
            </div>
          </div>
        </div>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
            Batal
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "tabAbsensiKedatangan",
  props: ["data"],
  data() {
    return {
      ipBackend,
      moment,
      bulan: "",
      tahun: "",
      dataAbsen: {},
      src1: "",
      src2: "",
      items: [
        {
          no: "-",
          tanggal: "-",
          jamMasuk: "-",
          jamKeluar: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "createdAt",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusIjin",
          label: "Status Absensi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "masuk",
          label: "Jam Masuk",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "break",
          label: "Jam Mulai Istirahat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "afterBreak",
          label: "Jam Selesai Istirahat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "keluar",
          label: "Jam Pulang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.dataAbsen.periode = moment().format("MM");
    this.dataAbsen.tahun = moment().format("YYYY");
    // this.getoptions();
    this.setPeriode();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setPeriode() {
      let vm = this;
      let p = vm.dataAbsen.periode;
      let t = vm.dataAbsen.tahun;
      if (p && t) {
        let q = moment(`${t}${p}${vm.data.tanggalGaji}`).format();
        let r = moment(q).subtract(1, "M").add(1, "days").format();
        vm.dataAbsen.tglAkhir = q;
        vm.dataAbsen.tglAwal = r;
        vm.getData();
      }
    },
    async getData() {
      let vm = this;
      console.log(this.data, "ihihih");
      vm.dataAbsen.dataKaryawanId = vm.data.dataKaryawanId;
      let absensi = await axios.post(
        ipBackend + "absensiKaryawanMobile/listKedatanganByDataKaryawanId/",
        vm.dataAbsen
      );
      // console.log(absensi.data, "aa");
      vm.items = absensi.data.data;
      vm.totalRows = absensi.data.data.length;
    },
  },
  watch: {
    data: function (newVal, oldVal) {
      if (newVal != oldVal) {
        // console.log(this.data,'ihihih')
        this.getData();
      }
    },
  },
};
</script>

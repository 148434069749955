<template>
  <div>
    <b-modal
      id="modal-delete-keluarga"
      size="md"
      centered
      title="Perhatian"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menghapus data ini ?</p>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-delete-keluarga')"
        >
          {{ $store.state.form.tidak }}
        </b-button>
        <b-button variant="primary" @click="hapus()"> Ya </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  name: "modalDelete",
  props: ["dataKeluarga"],
  data() {
    return {
      busy: false,
      button: "Hapus",
    };
  },
  methods: {
    hapus() {
      let vm = this;
      // console.log(vm.dataPendidikan, "ini data asuransi");
      vm.dataKeluarga.dataKeluargaKaryawanId = vm.dataKeluarga.id;
      axios
        .post(ipBackend + "dataKeluargaKaryawan/delete", vm.dataKeluarga)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Hapus";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGHAPUS DATA KELUARGA",
              showing: true,
            });
            this.$bvModal.hide("modal-delete-keluarga");
          } else {
            vm.button = "Hapus";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Hapus";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          this.$bvModal.hide("modal-delete-keluarga");
        });
    },
  },
};
</script>

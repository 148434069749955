<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark" fluid>
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Data Karyawan</strong>
              </h5>
            </template>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-tabs content-class="mt-3" align="center" fill>
                  <b-tab title="Belum Ditempatkan" active>
                    <Belum />
                  </b-tab>
                  <b-tab title="Sudah Ditempatkan">
                    <Sudah />
                  </b-tab>
                  <b-tab title="Non Aktif">
                    <NonAktif />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Sudah from "./karyawanSudah.vue";
import Belum from "./karyawanBelum.vue";
import NonAktif from "./karyawanNonAktif.vue";
export default {
  components: {
    Sudah,
    Belum,
    NonAktif
  },
  name: "karyawan",
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <b-tabs pills card vertical nav-wrapper-class="w-25">
      <b-tab title="Profil" active
        ><TabIdentitasDiri :data="data" @alertFromChild="triggerAlert($event)"
      /></b-tab>
      <b-tab title="Asuransi"
        ><TabAsuransi :asuransi="asuransi" :data="data"
      /></b-tab>
      <b-tab title="Riwayat Pendidikan"
        ><TabPendidikanProfil :data="data"
      /></b-tab>
      <b-tab title="Pengalaman Kerja"><TabKerjaProfil :data="data" /></b-tab>
      <b-tab title="Dokumen/Lampiran"><TabLampiranProfil :data="data" /></b-tab>
      <b-tab title="Keluarga"><TabKeluargaProfil :data="data" /></b-tab>
    </b-tabs>
  </div>
</template>
<script>
import TabIdentitasDiri from "../../../component/hcms/karyawan/tabIdentitasDiri.vue";
import TabAsuransi from "../../../component/hcms/karyawan/tabAsuransiProfil.vue";
import TabPendidikanProfil from "../../../component/hcms/karyawan/tabPendidikanProfil.vue";
import TabKerjaProfil from "../../../component/hcms/karyawan/tabKerjaProfil.vue";
import TabLampiranProfil from "../../../component/hcms/karyawan/tabLampiranProfil.vue";
import TabKeluargaProfil from "../../../component/hcms/karyawan/tabKeluargaProfil.vue";
export default {
  props: ["data", "asuransi"],
  components: {
    TabIdentitasDiri,
    TabAsuransi,
    TabPendidikanProfil,
    TabKerjaProfil,
    TabLampiranProfil,
    TabKeluargaProfil,
  },
  data() {
    return {
      editAsuransi: false,
    };
  },
  methods: {
    triggerAlert(event) {
      let vm = this;
      console.log("tab profil");
      vm.$emit("alertFromChild", event);
    },
  },
};
</script>

<style scoped>
.tab-content > .tab-pane {
  padding: 10px 15px;
  background-color: whitesmoke;
  border-radius: 10px;
}
</style>

<template>
  <div>
    <b-modal
      id="modal-delete-sudah"
      size="lg"
      centered
      title="Hapus / Pecat Karyawan"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Keterangan" label-cols-md="3">
          <multiselect
            :state="checkIfValid('masterKeteranganKaryawanKeluarId')"
            v-model="$v.dataPecat.masterKeteranganKaryawanKeluarId.$model"
            :options="pecat"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="keteranganKaryawanKeluar"
            track-by="id"
            @input="itikiwir(dataPecat.masterKeteranganKaryawanKeluarId)"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-delete-sudah')"
        >
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "modalInput",
  props: ["data", "pecat"],
  components: { Multiselect },
  data() {
    return {
      dataPecat: {
        masterKeteranganKaryawanKeluarId: "",
      },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataPecat, null, 4);
    },
    isValid() {
      return !this.$v.dataPecat.$invalid;
    },
    isDirty() {
      return this.$v.dataPecat.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataPecat: {
      masterKeteranganKaryawanKeluarId: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataPecat[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      //   console.log(x);
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.dataPecat.masterKeteranganKaryawanKeluarId =
        vm.dataPecat.masterKeteranganKaryawanKeluarId.id;
      vm.dataPecat.dataKaryawanId = vm.data.dataKaryawanId;
      vm.dataPecat.statusKerjaKaryawan = 2;
      //   console.log(vm.dataPecat, "ini datanyaa");
      axios
        .post(ipBackend + "dataKaryawan/pecatKaryawan", vm.dataPecat)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL HAPUS / PECAT KARYAWAN",
              showing: true,
            });
            this.dataPecat.masterKeteranganKaryawanKeluarId = "";
            this.$bvModal.hide("modal-delete-sudah");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>

<template>
  <div>
    <b-form-group label="Gaji Pokok" label-cols-md="3">
      <b-form-select
        :options="gapok"
        v-model="dataGaji.masterGajiId"
        @input="setNominal(dataGaji.masterGajiId)"
      ></b-form-select>
    </b-form-group>
    <b-form-group label="Nominal Gaji Pokok" label-cols-md="3">
      <b-form-input
        :disabled="dataGaji.id != 'fc39dbe3-4bfa-4400-8618-0eddf4a0fc65'"
        type="number"
        :options="gapok"
        v-model="dataGaji.nominalGaji"
      ></b-form-input>
    </b-form-group>

    <b-alert dismissible fade :show="showing" :variant="variant">{{
      msg
    }}</b-alert>

    <b-form-group label-cols-md="3">
      <b-button variant="primary" @click="simpan()">Simpan</b-button>
    </b-form-group>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  props: ["data"],
  data() {
    return {
      gapok: [
        { value: "", text: "Gapok 1" },
        { value: "", text: "Gapok 2" },
      ],
      dataGaji: {
        masterGajiId: "",
        nominalGaji: "",
        dataKaryawanId: "",
        namaGaji: "",
      },
      showing: false,
      msg: "",
      variant: "",
    };
  },
  created() {
    this.getGaji();
  },
  methods: {
    setNominal(x) {
      this.gapok.forEach((item) => {
        if (item.id == x) {
          this.dataGaji.nominalGaji = item.nominalGaji;
          this.dataGaji.namaGaji = item.namaGaji;
        }
      });
    },
    async getGaji() {
      let gaji = await axios.get(ipBackend + "masterGaji/list");
      this.gapok = gaji.data.data.map((item) => {
        let x = item.nominalGaji
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return {
          ...item,
          value: item.id,
          text: item.namaGaji + " - " + "Rp. " + x,
        };
      });

      let gajiKaryawan = await axios.get(
        ipBackend +
          "dataKaryawan/detailGajiByKaryawanId/" +
          this.data.dataKaryawanId
      );

      this.dataGaji.masterGajiId = gajiKaryawan.data.data[0].masterGajiId;
      // console.log(gajiKaryawan.data.data[0].masterGajiId,"dataGaji")
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.dataGaji.dataKaryawanId = vm.data.dataKaryawanId;
      axios
        .post(ipBackend + "dataKaryawan/addGajiKaryawan", vm.dataGaji)
        .then((res) => {
          // console.log(res.data.message);
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.variant = "success";
            vm.msg = "BERHASIL MENDAFTARKAN GAJI POKOK";
            vm.showing = true;
            vm.$emit("change");
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
  },
};
</script>

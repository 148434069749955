<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <!-- <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ $store.state.table.karyawan }}</strong>
              </h5>
            </template> -->

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model.lazy="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      @click="editData(item.item)"
                      ><CIcon name="cil-pencil" />{{ item.actions }}</b-button
                    >

                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail'"
                      v-b-modal.modal-detail
                      @click="(data = item.item), (detail = !detail)"
                      ><CIcon name="cil-info" />{{ item.actions }}</b-button
                    >

                    <!-- <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus / Pecat Karyawan'"
                      v-b-modal.modal-delete
                      @click="data = item.item"
                      ><CIcon name="cil-trash" />{{ item.actions }}</b-button
                    > -->
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3"> Total Data {{ totalRows }} </b-col>
              <b-col md="5" offset-md="4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- <modal-edit
      :data="data"
      :edit="edit"
      :asuransi="asuransi"
      @alertFromChild="triggerAlert($event)"
    /> -->
    <modal-detail :data="data" :detail="detail" />
    <!-- <modal-delete
      :data="data"
      :pecat="pecat"
      @alertFromChild="triggerAlert($event)"
    /> -->
    <modal-keluarga />

    <modal-data-karyawan
      :data="data"
      :edit="edit"
      :asuransi="asuransi"
      @alertFromChild="triggerAlert($event)"
    />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import modalKeluarga from "../../../component/hcms/karyawan/modalKeluarga.vue";
import moment from "moment";
moment.locale("id");
import modalDetail from "../../../component/hcms/karyawan/modalDetail.vue";
import modalDelete from "../../../component/hcms/karyawan/modalDelete.vue";
import modalDataKaryawan from "../../../component/hcms/karyawan/modalDataKaryawanBelum.vue";
export default {
  components: {
    modalDetail,
    modalDelete,
    modalKeluarga,
    modalDataKaryawan,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      asuransi: [],
      msg: "",
      data: "",
      edit: false,
      detail: false,
      pecat: [],
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatKaryawan",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "emailKaryawan",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaDivisi",
          label: "Divisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaPosisi",
          label: "Posisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-6 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["namaKaryawan"],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
    this.getAsuransi();
    this.getPecat();
  },
  methods: {
    editData(data) {
      let vm = this;
      vm.data = data;
      vm.edit = !vm.edit;

      if (vm.data != "") {
        vm.$bvModal.show("modal-data-karyawan-belum");
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getAsuransi() {
      let listAsuransi = await axios.get(ipBackend + "masterAsuransi/list");
      this.asuransi = listAsuransi.data.data;
    },

    async getPecat() {
      let listPecat = await axios.get(
        ipBackend + "masterKeteranganKaryawanKeluar/list"
      );
      this.pecat = listPecat.data.data;
    },
    async getDatas() {
      this.tableBusy = true;
      let listKaryawan = await axios.get(ipBackend + "dataKaryawan/listKaryawan");
      //   this.items = listKaryawan.data.data;
      // FOREACH diganti FOR
      // this.items.forEach((element, index) => {
      //   let x = this.items[index];
      //   x.no = index + 1;
      //   x.tglMulai = moment(x.tanggalMulaiKerja).format("LL");
      //   x.tglLahir = moment(x.tanggalLahirKaryawan).format("LL");
      // });
      console.log(listKaryawan)
      for (let i = 0; i < listKaryawan.data.data.length; i++) {
        let x = listKaryawan.data.data[i];
        if (x.statusPenempatan == 0) {
          this.items.push(x);
        }
      }
      for (let i = 0; i < this.items.length; i++) {
        let x = this.items[i];
        x.no = i + 1;

        x.tglLahir = moment(x.tanggalLahirKaryawan).format("LL");
        if (!x.tanggalMulaiKerja) {
          x.tglMulai = "-";
        } else {
          x.tglMulai = moment(x.tanggalMulaiKerja).format("LL");
        }
      }
      // console.log(this.items, "ini item");
      this.totalRows = this.items.length;
      this.tableBusy = false;
    },

    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getDatas();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
};
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <h4><strong>Data Potongan</strong></h4>
        <hr />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form>
          <b-form-group label="Nama Potongan" label-cols-md="3">
            <b-form-select
              :options="potongan"
              v-model="dataPotongan.namaPotongan"
              @input="setNominal(dataPotongan.namaPotongan)"
            ></b-form-select>
          </b-form-group>
        </b-form>
        <b-form>
          <b-form-group label="Jenis Potongan" label-cols-md="3">
            <b-form-input
              disabled
              type="text"
              v-model="dataPotongan.jenisPotongan"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <b-form>
          <b-form-group label="Nominal Potongan" label-cols-md="3">
            <b-form-input
              :disabled="dataPotongan.jenisPotongan == 'Tetap'"
              type="number"
              v-model="dataPotongan.nominalPotongan"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <!-- <b-form>
          <b-form-group label="Durasi Potongan" label-cols-md="3">
            <b-form-input
              type="number"
              v-model="dataPotongan.durasiPotongan"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <b-form>
          <b-form-group label="Jenis Durasi Potongan" label-cols-md="3">
            <b-form-select
              :options="jdt"
              v-model="dataPotongan.jenisDurasiPotongan"
            ></b-form-select>
          </b-form-group>
        </b-form> -->
        <!-- <b-form>
          <b-form-group label="Tangga Mulai" label-cols-md="3">
            <date-picker
              style="width: 100%"
              v-model="dataPotongan.tglAwalPotongan"
            ></date-picker>
          </b-form-group>
        </b-form> -->
        <b-form>
          <b-form-group label="Keterangan" label-cols-md="3">
            <b-form-textarea
              v-model="dataPotongan.keteranganPotongan"
            ></b-form-textarea>
          </b-form-group>
        </b-form>

        <b-button variant="primary" @click="simpan()"
          ><CIcon name="cil-plus" /> Tambah Data</b-button
        >
      </b-col>
    </b-row>

    <b-alert dismissible fade :show="showing" :variant="variant">{{
      msg
    }}</b-alert>

    <b-row class="mt-3">
      <b-col md="3">
        <b-form-group
          :label="$store.state.table.perHalaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          :label="$store.state.table.cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$store.state.table.cariHolder"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >{{ $store.state.table.hapusCari }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(nominalPotongan)="item">
            {{ convert(item.item.nominalPotongan) }}
          </template>
          <template #cell(statusPotongan)="item">
            <span v-if="item.item.statusPotongan == 1">Lunas</span>
            <span v-else>Berjalan</span>
          </template>
          <template #cell(actions)="item">
            <b-button
              v-if="
                item.item.jenisPotongan == 'Tidak Tetap' &&
                item.item.statusPotongan == 0
              "
              variant="danger"
              class="mr-1"
              v-c-tooltip.hover.click="'Lunas'"
              v-b-modal.modal-lunas
              @click="pelunasan = item.item"
              ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
            >
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
    <div>
      <b-modal
        id="modal-lunas"
        size="lg"
        centered
        scrollable
        title="Pelunasan Potongan"
        header-bg-variant="info"
        header-text-variant="light"
      >
        <p>
          Apakah anda yakin akan Melunaskan Potongan a.n
          <span class="text-danger">{{ data.namaKaryawan }}</span> pada
          <span class="text-danger">{{ pelunasan.namaPotongan }}</span> senilai
          <span class="text-danger">{{ pelunasan.nominalPotongan }}</span> ini?
        </p>

        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-lunas')">
            Batal
          </b-button>
          <b-button variant="primary" @click="lunas()"> Yakin </b-button>
        </template></b-modal
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import modalDelete from "../../../component/hcms/karyawan/potongan/modalDelete.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    DatePicker,
    modalDelete,
  },
  props: ["data"],
  data() {
    return {
      moment,
      items: [
        {
          no: "-",
          PotonganTetap: "-",
        },
      ],
      dataPotongan: {
        namaPotongan: "",
        nominalPotongan: "",
        jenisPotongan: "",
        keterangan: "",
        statusPotongan: 0
      },
      pelunasan: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaPotongan",
          label: "Nama Potongan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nominalPotongan",
          label: "Nominal Potongan",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },

        {
          key: "jenisPotongan",
          label: "Jenis Potongan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "tanggalLunas",
          label: "Tanggal Lunas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusPotongan",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-3 text-center",
        },
      ],
      variant: "",
      msg: "",
      showing: false,
      potongan: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    convert(x) {
      if (x) {
        return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
    setNominal(x) {
      console.log(x);
      this.dataPotongan.nominalPotongan = x.nominalPotongan;
      this.dataPotongan.jenisPotongan = x.jenisPotongan;
      this.dataPotongan.masterPotonganId = x.id;
    },
    async getDatas() {
      let vm = this;
      let potongan = await axios.get(ipBackend + "masterPotongan/list");
      // console.log(potongan);

      vm.potongan = potongan.data.data.map((item) => {
        return { ...item, value: item, text: item.namaPotongan };
      });

      // console.log(this.data);
      let potKaryawan = await axios.post(
        ipBackend + "poolPotongan/listPoolPotonganByDataKaryawanId/",
        { dataKaryawanId: vm.data.dataKaryawanId }
      );

      vm.items = potKaryawan.data.data;
      this.totalRows = vm.items.length;
      console.log(vm.items, "potpot");
    },
    lunas() {
      let vm = this;
      console.log(vm.pelunasan, "pelunasan");
      vm.pelunasan.id = vm.pelunasan.poolPotonganId;
      vm.pelunasan.statusPotongan = 1;
      vm.pelunasan.tanggalLunas = moment();
      axios
        .post(ipBackend + "poolPotongan/update", vm.pelunasan)
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.message == "sukses") {
              vm.$bvModal.hide("modal-lunas");
              vm.getDatas();
              vm.busy = false;
              vm.$emit("change");
              vm.variant = "success";
              vm.msg = "BERHASIL MELUNASI POTONGAN";
              vm.showing = true;
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.variant = "danger";
              vm.msg = _.toUpper(res.data.message);
              vm.showing = true;
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
            }
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.dataPotongan.dataKaryawanId = vm.data.dataKaryawanId;
      axios
        .post(ipBackend + "poolPotongan/register", vm.dataPotongan)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.variant = "success";
            vm.msg = "BERHASIL MENDAFTARKAN POTONGAN";
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
            this.getDatas();
            vm.$emit("change");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.$emit("change");
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-input-peringatan"
      size="lg"
      centered
      title="Tambah Data Peringatan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Tanggal" label-cols-md="3">
          <date-picker
            style="width: 100%"
            :state="checkIfValid('tanggalPeringatan')"
            v-model="$v.dataPeringatan.tanggalPeringatan.$model"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Jenis Pelanggaran" label-cols-md="3">
          <multiselect
            :state="checkIfValid('poolPelanggaranId')"
            v-model="$v.dataPeringatan.poolPelanggaranId.$model"
            :options="dataPelanggaran"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaPelanggaran"
            @input="itikiwir(dataPeringatan.poolPelanggaranId)"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Keterangan" label-cols-md="3">
          <b-form-textarea
            rows="3"
            :state="checkIfValid('keteranganPeringatan')"
            v-model="$v.dataPeringatan.keteranganPeringatan.$model"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Masa Berlaku ( Hari )" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('masaBerlakuPeringatan')"
            v-model="$v.dataPeringatan.masaBerlakuPeringatan.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-peringatan')"
        >
          {{ $store.state.form.batal }}
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
    Multiselect,
  },
  name: "modalInput",
  props: ["data", "dataPelanggaran"],
  data() {
    return {
      dataPeringatan: {
        tanggalPeringatan: "",
        keteranganPeringatan: "",
        masaBerlakuPeringatan: "",
        poolPelanggaranId: "",
      },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataPeringatan, null, 4);
    },
    isValid() {
      return !this.$v.dataPeringatan.$invalid;
    },
    isDirty() {
      return this.$v.dataPeringatan.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataPeringatan: {
      tanggalPeringatan: {
        required,
      },
      keteranganPeringatan: {
        required,
      },
      masaBerlakuPeringatan: {
        required,
      },
      poolPelanggaranId: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataPeringatan[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      // console.log(x.poolPelanggaranId);
    },

    simpan() {
      let vm = this;
      vm.dataPeringatan.poolPelanggaranId =
        vm.dataPeringatan.poolPelanggaranId.poolPelanggaranId;
      vm.dataPeringatan.tanggalPeringatan = moment(
        vm.dataPeringatan.tanggalPeringatan
      );
      // console.log(vm.dataPeringatan, "ini data peringatan");
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        axios
          .post(ipBackend + "peringatan/register", vm.dataPeringatan)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN DATA PERINGATAN",
                showing: true,
              });
              this.dataPeringatan.keteranganPeringatan = "";
              this.dataPeringatan.masaBerlakuPeringatan = "";
              this.dataPeringatan.nomorSuratPeringatan = "";
              this.$v.$reset()
              this.$bvModal.hide("modal-input-peringatan");
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-input-pendidikan"
      size="lg"
      centered
      title="Tambah Data Riwayat Pendidikan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Tingkat Pendidikan" label-cols-md="3">
          <b-form-select
            :options="pendidikanTerakhir"
            v-model="dataPendidikan.tingkatPendidikan"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Nama Sekolah" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="dataPendidikan.namaSekolah"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Tahun Kelulusan" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="dataPendidikan.tahunLulus"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Jurusan" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="dataPendidikan.jurusan"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="primary"
          :disabled="busy"
          @click="simpanPendidikan()"
          >{{ button }}</b-button
        >
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-pendidikan')"
        >
          {{ $store.state.form.batal }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  props: ["data"],
  data() {
    return {
      button: "Simpan",
      busy: false,
      dataPendidikan: {
        tingkatPendidikan: "",
        tahunLulus: "",
        namaSekolah: "",
        jurusan: "",
      },
      pendidikanTerakhir: [
        { value: "SD", text: "SD" },
        { value: "SMP", text: "SMP" },
        { value: "MTs", text: "MTs" },
        { value: "SMA", text: "SMA" },
        { value: "SMK", text: "SMK" },
        { value: "MA", text: "MA" },
        { value: "D-1", text: "D-1" },
        { value: "D-2", text: "D-2" },
        { value: "D-3", text: "D-3" },
        { value: "D-4", text: "D-4" },
        { value: "S-1", text: "S-1" },
        { value: "S-2", text: "S-2" },
        { value: "Profesi", text: "Profesi" },
        { value: "Kejar Paket C", text: "Kerjar Paket C" },
      ],
    };
  },
  methods: {
    simpanPendidikan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.dataPendidikan.dataKaryawanId = vm.data.dataKaryawanId;
      axios
        .post(
          ipBackend + "dataKaryawan/tambahRiwayatPendidikan",
          vm.dataPendidikan
        )
        .then((res) => {
          console.log(res, "ini res");
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN RIWAYAT PENDIDIKAN",
              showing: true,
            });
            vm.reset();
            vm.$bvModal.hide("modal-input-pendidikan");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          vm.$bvModal.hide("modal-input-pendidikan");
        });
    },
    reset() {
      let vm = this;
      vm.dataPendidikan.tingkatPendidikan = "";
      vm.dataPendidikan.namaSekolah = "";
      vm.dataPendidikan.tahunLulus = "";
      vm.dataPendidikan.jurusan = "";
    },
  },
};
</script>

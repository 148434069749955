<template>
  <div>
    <b-modal
      id="modal-delete-lampiran"
      size="md"
      centered
      title="Perhatian"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menghapus data ini ?</p>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-delete-lampiran')"
        >
          {{ $store.state.form.tidak }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-data-karyawan-sudah"
      size="xl"
      centered
      scrollable
      title="Profil Karyawan"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-tabs content-class="mt-3" align="center">
            <b-tab title="Profil" active>
              <tab-profil
                :data="data"
                :asuransi="asuransi"
                @alertFromChild="triggerAlert($event)"
              />
            </b-tab>
            <b-tab title="Absensi">
              <tab-absensi :data="data" />
            </b-tab>
            <!-- <b-tab title="Penilaian">
              <tab-penilaian />
            </b-tab> -->
            <b-tab title="Pelanggaran">
              <tab-pelanggaran :data="data" @change="change = !change" />
            </b-tab>
            <b-tab title="Peringatan">
              <tab-peringatan :data="data" :change="change" />
            </b-tab>
            <!-- <b-tab title="Perjanjian">
              <tab-perjanjian />
            </b-tab> -->
            <b-tab title="Penggajian">
              <tab-penggajian :data="data" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <template #modal-footer>
        <!-- <b-alert dismissible fade :show="showing" :variant="variant">{{
          msg
        }}</b-alert> -->
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-data-karyawan-sudah')"
        >
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import tabProfil from "../tabProfil.vue";
import tabAbsensi from "../tabAbsensi.vue";
import tabPenilaian from "../tabPenilaian.vue";
import tabPelanggaran from "../tabPelanggaran.vue";
import tabPeringatan from "../tabPeringatan.vue";
// import tabPerjanjian from "../../../component/hcms/karyawan/tabPerjanjian.vue";
import tabPenggajian from "../tabPenggajian.vue";
export default {
  name: "modalDataKaryawanSudah",
  props: ["data", "edit", "asuransi"],
  components: {
    tabProfil,
    tabAbsensi,
    tabPenilaian,
    tabPelanggaran,
    tabPeringatan,
    // tabPerjanjian,
    tabPenggajian,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      change: false,
    };
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        // console.log(this.data, "ini datanya");
      }
    },
  },
  methods: {
    triggerAlert(event) {
      let vm = this;
      console.log("modal data karyawan sudah");
      this.$bvModal.hide("modal-data-karyawan-sudah");
      vm.$emit("alertFromChild", event);
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-edit-pendidikan"
      size="lg"
      centered
      title="Edit Data Riwayat Pendidikan"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Tingkat Pendidikan" label-cols-md="3">
          <b-form-select :options="lampiran"></b-form-select>
        </b-form-group>

        <b-form-group label="Nama Sekolah" label-cols-md="3">
          <b-form-input type="text"></b-form-input>
        </b-form-group>

        <b-form-group label="Tahun Kelulusan" label-cols-md="3">
          <b-form-input type="text"></b-form-input>
        </b-form-group>

        <b-form-group label="Jurusan" label-cols-md="3">
          <b-form-input type="text"></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-pendidikan')"
        >
          {{ $store.state.form.batal }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lampiran: [
        { value: "", text: "Lampiran 1" },
        { value: "", text: "Lampiran 2" },
      ],
    };
  },
};
</script>

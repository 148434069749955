<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <h4><strong>Data Ijin Kedatangan</strong></h4>
        <hr />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col md="3">
        <b-form-group
          :label="$store.state.table.perHalaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          :label="$store.state.table.cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$store.state.table.cariHolder"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >{{ $store.state.table.hapusCari }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(tglIjin)="item">
            {{ moment(item.item.tglIjin).format("ll") }}
          </template>
          <template #cell(statusIjin)="item">
            <p v-if="item.item.statusIjin == 4">Ijin</p>
            <p v-if="item.item.statusIjin == 5">Sakit</p>
            <p v-if="item.item.statusIjin == 6">Alpha/Mangkir</p>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "tabIjin",
  props: ["data"],
  data() {
    return {
      moment,
      items: [
        {
          no: "-",
          tanggal: "-",
          keterangan: "-",
          totalIjin: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "tglIjin",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusIjin",
          label: "keterangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "totalIjin",
        //   label: "Total Ijin",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      let vm = this;
      vm.items = [];
      let ijin = await axios.get(
        ipBackend +
          "absensiKaryawanMobile/listIjinByDataKaryawanId/" +
          vm.data.dataKaryawanId
      );

      console.log(ijin.data.data, "ijin");

      vm.items = ijin.data.data;
    },
  },
};
</script>

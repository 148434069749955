<template>
  <div>
    <b-tabs pills card vertical nav-wrapper-class="w-25">
      <b-tab title="Take Home Pay" active>
        <TabTakeHomePay :data="data" :change="change" />
      </b-tab>
      <b-tab title="Gaji Pokok">
        <TabGajiPokok :data="data" @change="change = !change" :change="change" />
      </b-tab>
      <b-tab title="Tunjangan"><TabTunjangan :data="data" @change="change = !change" :change="change" /></b-tab>
      <b-tab title="Insentif"><TabInsentif :data="data" @change="change = !change" :change="change" /></b-tab>
      <b-tab title="Potongan"><TabPotongan :data="data" @change="change = !change" :change="change" /></b-tab>
      <b-tab title="Denda"><TabDenda :data="data" @change="change = !change" :change="change" /></b-tab>
    </b-tabs>
  </div>
</template>
<script>
import TabGajiPokok from "../../../component/hcms/karyawan/tabGajiPokok.vue";
import TabTunjangan from "../../../component/hcms/karyawan/tabTunjangan.vue";
import TabInsentif from "../../../component/hcms/karyawan/tabTunjanganTidakTetap.vue"
import TabPotongan from "../../../component/hcms/karyawan/tabPotongan.vue";
import TabTakeHomePay from "../../../component/hcms/karyawan/tabTotalGaji.vue";
import TabDenda from "../../../component/hcms/karyawan/tabDenda.vue";
export default {
  components: {
    TabGajiPokok,
    TabTunjangan,
    TabInsentif,
    TabTakeHomePay,
    TabPotongan,
    TabDenda
  },
  props:['data'],
  data() {
    return {
      change: false
    };
  },
  methods:{
    show(){
      // console.log(this.change)
    }
  },
  watch:{
    data:function(newVal, oldVal){
      if(newVal != oldVal){
        this.change = !this.change
      }
    }
  }
};
</script>

<style scoped>
.tab-content > .tab-pane {
  padding: 10px 15px;
  background-color: whitesmoke;
  border-radius: 10px;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col md="5">
        <b-form-group label="Periode" label-cols-md="3">
          <b-form-select
            :options="periode"
            type="text"
            @change="setTotal()"
            v-model="bulan"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="5">
        <b-form-group label="Tahun" label-cols-md="3">
          <b-form-select
            :options="thn"
            type="text"
            @change="setTotal()"
            v-model="tahun"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-button variant="success" @click="rekap()">Rekap Gaji</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="5">
        <b-form-group label="Mulai" label-cols-md="3">
          <date-picker
            style="width: 100%"
            v-model="data.tglAwal"
            :placeholder="holders(data.tglAwal)"
            @change="getDatas()"
          ></date-picker>
        </b-form-group>
      </b-col>
      <b-col md="5">
        <b-form-group label="Akhir" label-cols-md="3">
          <date-picker
            style="width: 100%"
            v-model="data.tglAkhir"
            :placeholder="holders(data.tglAkhir)"
            @change="getDatas()"
          ></date-picker>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="ok">
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <h4><strong>Total Take Home Pay</strong></h4>
          <hr />
        </b-col>
      </b-row>
      <b-alert dismissible fade :show="showing" :variant="variant">{{
        msg
      }}</b-alert>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table-simple borderless small>
            <b-tbody>
              <b-tr v-if="dataTHP.proret > 0">
                <b-td style="width: 35%">Gaji Pokok (Prorata)</b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right"> {{ convert(dataTHP.proret) }}</b-td>
              </b-tr>
              <b-tr v-else>
                <b-td style="width: 35%">Gaji Pokok</b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right">
                  {{ convert(dataTHP.nominalGajiKaryawan) }}</b-td
                >
              </b-tr>

              <b-tr
                v-for="(item, idx) in dataTHP.tunjangan"
                :key="item.poolTunjanganId"
              >
                <b-td v-if="idx == 0">Tunjangan</b-td>
                <b-td v-else></b-td>
                <b-td></b-td>
                <b-td>{{ item.namaTunjangan }}</b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right">
                  {{ convert(item.nominalTunjangan) }}</b-td
                >
              </b-tr>

              <b-tr
                v-for="(item, idx) in dataTHP.tunjanganTidakTetap"
                :key="item.id"
              >
                <b-td v-if="idx == 0">Insentif</b-td>
                <b-td v-else></b-td>
                <b-td></b-td>
                <b-td>{{ item.namaTunjangan }}</b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right"> {{ convert(item.insentif) }}</b-td>
              </b-tr>

              <b-tr v-if="dataTHP.totalLembur != 0">
                <b-td>Lembur</b-td>
                <b-td></b-td>
                <b-td></b-td>
                <!-- <b-td
                  >{{ item.namaTunjangan }}</b-td
                > -->
                <b-td>: Rp. </b-td>
                <b-td class="text-right">
                  {{ convert(dataTHP.totalLembur) }}</b-td
                >
              </b-tr>

              <!-- <b-tr
                v-for="(item, idx) in dataTHP.tunjanganTidakTetap"
                :key="item.poolTunjanganTidakTetapId"
              >
                <b-td v-if="idx == 0">Tunjangan Tidak Tetap</b-td>
                <b-td v-else></b-td>
                <b-td></b-td>
                <b-td>{{ item.namaTunjanganTidakTetap }}</b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right">{{
                  convert(item.nominalTunjanganTidakTetap)
                }}</b-td>
              </b-tr> -->

              <b-tr
                v-for="(item, idx) in dataTHP.asuransi"
                :key="item.masterAsuransiId"
              >
                <b-td v-if="idx == 0">Potongan Asuransi</b-td>
                <b-td v-else></b-td>
                <b-td></b-td>
                <b-td>{{ item.namaAsuransi }}</b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right">
                  - {{ convert(item.potonganAsuransi) }}</b-td
                >
              </b-tr>

              <b-tr v-for="(item, idx) in dataTHP.potongan" :key="item.idx">
                <b-td v-if="idx == 0">Potongan</b-td>
                <b-td v-else></b-td>
                <b-td></b-td>
                <b-td>{{ item.namaPotongan }}</b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right">
                  - {{ convert(item.nominalPotongan) }}</b-td
                >
              </b-tr>

              <b-tr v-for="(item, idx) in dataTHP.denda" :key="item.idx">
                <b-td v-if="idx == 0">Denda</b-td>
                <b-td v-else></b-td>
                <b-td></b-td>
                <b-td>{{ item.namaDenda }} ({{ item.jumlah }})</b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right"> - {{ convert(item.nominal) }}</b-td>
              </b-tr>

              <b-tr>
                <b-td class="font-weight-bold">Total Take Home Pay</b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td>: Rp. </b-td>
                <b-td class="text-right font-weight-bold">{{
                  convert(totalTHP)
                }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <!-- 
    <modal-input />
    <modal-edit /> -->
      <!-- <modal-delete :dataTunjanganTetap="dataTunjanganTetap" @alert="triggerAlert($event), getDatas()" /> -->
    </div>
  </b-container>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    DatePicker,
  },
  props: ["data", "change"],
  data() {
    return {
      thn: [],
      periode: [
        { value: "01", text: "Januari" },
        { value: "02", text: "Februari" },
        { value: "03", text: "Maret" },
        { value: "04", text: "April" },
        { value: "05", text: "Mei" },
        { value: "06", text: "Juni" },
        { value: "07", text: "Juli" },
        { value: "08", text: "Agustus" },
        { value: "09", text: "September" },
        { value: "10", text: "Oktober" },
        { value: "11", text: "November" },
        { value: "12", text: "Desember" },
      ],
      bulan: "",
      tahun: "",
      moment,
      items: [
        {
          no: "-",
          tunjanganTetap: "-",
        },
      ],
      dataTHP: [],
      ok: false,
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaTunjangan",
          label: "Tunjangan Tetap",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nominalTunjangan",
          label: "Nominal Tunjangan Tetap",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-3 text-center",
        },
      ],
      variant: "",
      msg: "",
      showing: false,
      button: "Rekap Gaji",
      tunTetap: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    totalTHP() {
      let vm = this;
      let x = vm.dataTHP;
      x.gaji = 0;
      x.totalPotongan = 0;
      x.totalTunjangan = 0;
      x.totalDenda = 0;
      x.totalInsentif = 0;
      x.totalAsuransi = 0;
      if (x.proret > 0) {
        x.gaji += Number(x.proret);
      } else {
        if (x.nominalGajiKaryawan) {
          x.gaji += Number(x.nominalGajiKaryawan);
        }
      }

      if (x.tunjangan) {
        x.tunjangan.forEach((ele) => {
          x.totalTunjangan += Number(ele.nominalTunjangan);
        });
      }
      if (x.tunjanganTidakTetap) {
        x.tunjanganTidakTetap.forEach((ele) => {
          x.totalInsentif += Number(ele.insentif);
        });
      }
      if (x.absensi.length) {
        x.denda.forEach((ele) => {
          x.totalDenda += Number(ele.nominal);
        });
      }
      if (x.asuransi) {
        x.asuransi.forEach((ele) => {
          x.totalAsuransi += Number(ele.potonganAsuransi);
        });
      }
      if (x.potongan) {
        x.potongan.forEach((ele) => {
          x.totalPotongan += Number(ele.nominalPotongan);
        });
      }
      x.takeHomePay = Math.floor(
        x.gaji +
          x.totalInsentif +
          x.totalLembur +
          x.totalTunjangan -
          x.totalPotongan -
          x.totalAsuransi -
          x.totalDenda
      );

      // console.log(
      //   x.gaji,
      //   "gaji",
      // x.totalInsentif,
      // "insentif",
      //   x.totalLembur,
      //   "lembur",
      //   x.totalTunjangan,
      //   "tunjangan",
      //   x.totalPotongan,
      //   "potongan",
      //   x.totalAsuransi,
      //   "asuransi",
      //   x.totalDenda,
      //   "denda"
      // );
      return x.takeHomePay;
    },
  },
  mounted() {
    // console.log(this.data, "<<");
    this.bulan = moment().format("MM");
    this.tahun = moment().format("YYYY");
    this.getoptions();
    if (this.data.tanggalClosing == 25) {
      this.setPeriode25();
    } else {
      this.setPeriode1();
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setTotal() {
      this.getoptions();
      if (this.data.tanggalClosing == 25) {
        this.setPeriode25();
      } else {
        this.setPeriode1();
      }
    },
    convert(x) {
      if (x) {
        return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
    holders(x) {
      if (x) {
        return moment(x).format("LL");
      } else {
        return "";
      }
    },
    getoptions() {
      for (let i = 2000; i < 2050; i++) {
        this.thn.push(i);
      }
    },
    setPeriode1() {
      let p = this.bulan;
      let t = this.tahun;
      let date = "01";
      console.log(this.data, "close");
      if (p && t) {
        let q = moment(`${t}${p}${date}`).format("YYYY-MM-DD");
        let r = moment(q).add(1, "M").subtract(1, "days").format("YYYY-MM-DD"); // a
        this.data.tglAkhir = r;
        this.data.tglAwal = q;
      }
      this.getDatas();
    },
    setPeriode25() {
      let p = this.bulan;
      let t = this.tahun;
      if (p && t) {
        let q = moment(`${t}${p}25`).format("YYYY-MM-DD");
        let r = moment(q).subtract(1, "M").add(1, "days").format("YYYY-MM-DD"); // a
        this.data.tglAkhir = q;
        this.data.tglAwal = r;
      }
      this.getDatas();
    },
    async getDatas() {
      let vm = this;
      vm.ok = false;
      let THP = await axios.post(
        ipBackend + "dataKaryawan/gajiKaryawan",
        vm.data
      );
      // console.log(THP.data.data, "thp");
      vm.dataTHP = THP.data.data[0];
      vm.dataTHP.denda = [];
      vm.dataTHP.totalLembur = 0;
      if (vm.dataTHP.absensi.length) {
        vm.dataTHP.perHari = Number(vm.dataTHP.nominalGajiKaryawan / 25);
        let x = Number(0);
        let y = Number(0);
        x += Number(vm.dataTHP.absensi[0].masukTelat);
        x += Number(vm.dataTHP.absensi[0].keluarTelat);
        x += Number(vm.dataTHP.absensi[0].istirahatTelat);
        y += Number(vm.dataTHP.absensi[0].jumlahIjin);
        vm.dataTHP.totalIjin = y;
        vm.dataTHP.absensi[0].jumlahTelat = x;
        vm.dataTHP.totalBerangkat =
          vm.dataTHP.absensi[0].totalBerangkat -
          vm.dataTHP.absensi[0].jumlahIjin;
        vm.dataTHP.denda.push({
          namaDenda: "Keterlambatan",
          nominal: x * 25000,
          jumlah: x,
        });
        vm.dataTHP.denda.push({
          namaDenda: "Ijin/Mangkir",
          nominal: y * vm.dataTHP.perHari,
          jumlah: y,
        });
      }
      vm.dataTHP.asuransi.push({
        namaAsuransi: "Ketenagakerjaan",
        potonganAsuransi: vm.dataTHP.bpjsKetenagaKerjaan,
      });

      // if (vm.dataTHP.lembur) {
      vm.dataTHP.lembur.forEach((ele) => {
        vm.dataTHP.totalLembur += Number(ele.nominal);
      });
      // }

      if (vm.dataTHP.tunjanganTidakTetap) {
        vm.dataTHP.tunjanganTidakTetap.forEach((ele) => {
          if (
            ele.masterTunjanganTidakTetapId ==
            "a11c02f8-7e43-4957-b8c1-c7a5a8fcd911"
          ) {
            ele.insentif = ele.nominalTunjangan * vm.dataTHP.totalBerangkat;
          } else {
            if (vm.dataTHP.totalIjin) {
              ele.insentif =
                ele.maksimalTunjangan -
                ele.nominalTunjangan * vm.dataTHP.totalIjin;
            } else {
              ele.insentif = ele.maksimalTunjangan;
            }
          }
          console.log(ele.insentif);
        });
      }

      if (vm.dataTHP.bpjsLainnya) {
        vm.dataTHP.asuransi.push({
          namaAsuransi: "Kesehatan",
          potonganAsuransi: vm.dataTHP.bpjsKesehatan + vm.dataTHP.bpjsLainnya,
        });
      } else {
        vm.dataTHP.asuransi.push({
          namaAsuransi: "Kesehatan",
          potonganAsuransi: vm.dataTHP.bpjsKesehatan,
        });
      }
      if (moment(vm.data.tglAkhir) > moment(vm.data.tglAwal)) {
        vm.ok = true;
      }
    },
    rekap() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.peridoAwal = vm.data.tglAwal;
      vm.data.peridoAkhir = vm.data.tglAkhir;
      vm.data.dataRekap = vm.dataTHP;
      axios
        .post(ipBackend + "rekapGaji/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = this.$store.state.form.simpan;
            vm.busy = false;
            vm.variant = "success";
            vm.msg = "BERHASIL MENDAFTARKAN REKAP GAJI";
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 3000);
          } else {
            vm.button = this.$store.state.form.simpan;
            vm.busy = false;
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 3000);
          }
        })
        .catch((err) => {
          vm.button = this.$store.state.form.simpan;
          vm.busy = false;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 3000);
        });
    },
  },
  watch: {
    change: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.data.tanggalClosing == 25) {
          this.setPeriode25();
        } else {
          this.setPeriode1();
        }
      }
    },
  },
};
</script>

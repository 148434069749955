<template>
  <div>
    <b-tabs pills card vertical nav-wrapper-class="w-25">
      <!-- <b-tab title="Shift" active><TabShiftKaryawan :data="data" /></b-tab> -->
      <b-tab title="Absensi Kedatangan"><TabAbsensiKedatangan :data="data" /></b-tab>
      <!-- <b-tab title="Ijin"><TabIjin :data="data" /></b-tab> -->
    </b-tabs>
  </div>
</template>
<script>
import TabAbsensiKedatangan from "../../../component/hcms/karyawan/tabAbsensiKedatangan.vue";
import TabIjin from "../../../component/hcms/karyawan/tabIjin.vue";
// import TabShiftKaryawan from "./tabShiftKaryawan.vue"
export default {
  props:['data'],
  components: {
    TabAbsensiKedatangan,
    TabIjin,
    // TabShiftKaryawan
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.tab-content > .tab-pane {
  padding: 10px 15px;
  background-color: whitesmoke;
  border-radius: 10px;
}
</style>
<template>
  <div>
    <b-modal
      id="modal-edit-asuransi"
      size="lg"
      centered
      title="Edit Data Asuransi"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Asuransi" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="dataAsuransi.namaAsuransi"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Potongan" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('potonganAsuransi')"
            v-model="$v.dataAsuransi.potonganAsuransi.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nomor" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('nomorAsuransi')"
            v-model="$v.dataAsuransi.nomorAsuransi.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="primary"
          :disabled="!isValid || busy"
          @click="updateAsuransi()"
          >{{ button }}</b-button
        >
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-asuransi')"
        >
          {{ $store.state.form.batal }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "modalEdit",
  props: ["data", "dataAsuransi"],
  data() {
    return {
      button: "Simpan",
      busy: false,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataAsuransi, null, 4);
    },
    isValid() {
      return !this.$v.dataAsuransi.$invalid;
    },
    isDirty() {
      return this.$v.dataAsuransi.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataAsuransi: {
      masterAsuransiId: {
        required,
      },
      potonganAsuransi: {
        required,
      },
      nomorAsuransi: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataAsuransi[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    updateAsuransi() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.dataAsuransi.poolPotonganAsuransiId = vm.dataAsuransi.poolPotonganId;
      // vm.dataAsuransi.dataKaryawanId = vm.data.dataKaryawanId;
      axios
        .post(ipBackend + "poolPotonganAsuransi/update", vm.dataAsuransi)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH DATA ASURANSI",
              showing: true,
            });
            vm.reset();
            vm.$bvModal.hide("modal-edit-asuransi");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    reset() {
      let vm = this;
      vm.$v.$reset();
      vm.dataAsuransi.masterAsuransiId = "";
      vm.dataAsuransi.nomorAsuransi = "";
      vm.dataAsuransi.potonganAsuransi = "";
    },
  },
};
</script>

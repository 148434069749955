<template>
  <div>
    <b-modal
      id="modal-edit-keluarga"
      size="lg"
      centered
      title="Edit Data Keluarga"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            :state="checkIfValid('nama')"
            v-model="$v.dataKeluarga.nama.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nomor KTP <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            :state="checkIfValid('nomorKTP')"
            v-model="$v.dataKeluarga.nomorKTP.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Agama" label-cols-md="3">
          <b-form-select
            :options="agama"
            v-model="dataKeluarga.agama"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Kebangsaan" label-cols-md="3">
          <b-form-select
            :options="kebangsaan"
            v-model="dataKeluarga.kebangsaan"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Jenis Kelamin" label-cols-md="3">
          <b-form-select
            :options="jenisKelamin"
            v-model="dataKeluarga.jenisKelamin"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Tempat Lahir" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="dataKeluarga.tempatLahir"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Tanggal Lahir" label-cols-md="3">
          <date-picker
            style="width: 100%"
            v-model="dataKeluarga.tanggalLahir"
            :placeholder="dataKeluarga.tglLahir"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Email
          </template>
          <b-form-input
            type="text"
            v-model="dataKeluarga.email"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Alamat <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            type="text"
            :state="checkIfValid('alamat')"
            v-model="$v.dataKeluarga.alamat.$model"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Status Dalam Keluarga" label-cols-md="3">
          <b-form-select
            :options="statusKeluarga"
            v-model="dataKeluarga.statusDalamKeluarga"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Status Asuransi" label-cols-md="3">
          <b-form-select
            :options="asuransi"
            v-model="dataKeluarga.statusAsuransi"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="No. BPJS Kesehatan" label-cols-md="3">
          <b-form-input
            type="number"
            v-model="dataKeluarga.nomorBpjsKesehatan"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="primary"
          :disabled="!isValid || busy"
          @click="simpanKeluarga()"
          >{{ button }}</b-button
        >
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-keluarga')"
        >
          {{ $store.state.form.batal }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  props: ["data", "dataKeluarga"],
  components: {
    DatePicker,
  },
  data() {
    return {
      button: "Simpan",
      busy: false,
      kebangsaan: [
        { value: "WNI", text: "WNI" },
        { value: "WNA", text: "WNA" },
      ],
      agama: [
        { value: "Islam", text: "Islam" },
        { value: "Katholik", text: "Katholik" },
        { value: "Protestan", text: "Protestan" },
        { value: "Hindu", text: "Hindu" },
        { value: "Budha", text: "Budha" },
        { value: "Khonghucu", text: "Khonghucu" },
      ],
      asuransi: [
        { value: 0, text: "Inti" },
        { value: 1, text: "Lainnya" },
      ],
      jenisKelamin: [
        { value: "Laki-laki", text: "Laki-laki" },
        { value: "Perempuan", text: "Perempuan" },
      ],
      statusKeluarga: [
        { value: "Suami", text: "Suami" },
        { value: "Istri", text: "Istri" },
        { value: "Anak", text: "Anak" },
      ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataKeluarga, null, 4);
    },
    isValid() {
      return !this.$v.dataKeluarga.$invalid;
    },
    isDirty() {
      return this.$v.dataKeluarga.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataKeluarga: {
      nama: {
        required,
      },
      nomorKTP: {
        required,
      },
      // email: {
      //   required,
      // },
      alamat: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataKeluarga[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpanKeluarga() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      axios
        .post(ipBackend + "dataKeluargaKaryawan/update", vm.dataKeluarga)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH DATA KELUARGA",
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-keluarga");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          vm.$bvModal.hide("modal-edit-keluarga");
        });
    },
    reset() {
      let vm = this;
      vm.$v.$reset();
      vm.dataKeluarga.nama = "";
      vm.dataKeluarga.nomorKTP = "";
      vm.dataKeluarga.agama = "";
      vm.dataKeluarga.kebangsaan = "";
      vm.dataKeluarga.jenisKelamin = "";
      vm.dataKeluarga.tempatLahir = "";
      vm.dataKeluarga.tanggalLahir = "";
      vm.dataKeluarga.email = "";
      vm.dataKeluarga.alamat = "";
      vm.dataKeluarga.statusDalamKeluarga = "";
    },
  },
};
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <h4><strong>Edit Profil</strong></h4>
        <hr />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form>
          <b-row style="margin-bottom: 2%">
            <b-col offset="9">
              <b-button
                v-if="!data.username && !data.password"
                variant="primary"
                @click="generate"
                >Generate Credential</b-button
              >
            </b-col>
          </b-row>

          <b-form-group label="Username" label-cols-md="3">
            <b-form-input
              v-if="!data.username"
              type="text"
              v-model="username"
            ></b-form-input>
            <b-form-input
              v-else
              type="text"
              v-model="data.username"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Password" label-cols-md="3">
            <b-form-input
              v-if="!data.password"
              type="text"
              v-model="password"
            ></b-form-input>
            <b-form-input
              v-else
              type="password"
              v-model="data.password"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label="NIP" label-cols-md="3">
            <b-form-input type="text" v-model="data.NIK"></b-form-input>
          </b-form-group>

          <b-form-group label="Nama Karyawan" label-cols-md="3">
            <b-form-input
              type="text"
              v-model="data.namaKaryawan"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="No. KTP" label-cols-md="3">
            <b-form-input
              type="text"
              v-model="data.nomorKTPKaryawan"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Posisi" label-cols-md="3">
            <b-form-input
              type="text"
              v-model="data.namaPosisi"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Divisi" label-cols-md="3">
            <b-form-input
              type="text"
              v-model="data.namaDivisi"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Tinggi Badan" label-cols-md="3">
            <b-form-input
              type="number"
              v-model="data.tinggiBadanKaryawan"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Berat Badan" label-cols-md="3">
            <b-form-input
              type="number"
              v-model="data.beratBadanKaryawan"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Golongan Darah" label-cols-md="3">
            <b-form-select
              :options="gol"
              v-model="data.golonganDarahKaryawan"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Agama" label-cols-md="3">
            <b-form-select
              :options="agama"
              v-model="data.agamaKaryawan"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Kebangsaan" label-cols-md="3">
            <b-form-select
              :options="kebangsaan"
              v-model="data.kebangsaanKaryawan"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Jenis Kelamin" label-cols-md="3">
            <b-form-select
              :options="jenisKelamin"
              v-model="data.jenisKelaminKaryawan"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Tempat Lahir" label-cols-md="3">
            <b-form-input
              type="text"
              v-model="data.tempatLahirKaryawan"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Tanggal Lahir" label-cols-md="3">
            <date-picker
              style="width: 100%"
              v-model="data.tanggalLahirKaryawan"
              :placeholder="moment(data.tanggalLahirKaryawan).format('LL')"
            ></date-picker>
          </b-form-group>

          <b-form-group label="No. Telepon/Hp" label-cols-md="3">
            <b-form-input
              type="number"
              v-model="data.noHpKaryawan"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="SIM" label-cols-md="3">
            <b-form-select
              :options="sim"
              v-model="data.SIM"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Email" label-cols-md="3">
            <b-form-input
              type="text"
              v-model="data.emailKaryawan"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Kota Domisili" label-cols-md="3">
            <b-form-input
              type="text"
              v-model="data.kotaDomisili"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Alamat" label-cols-md="3">
            <b-form-textarea
              type="text"
              v-model="data.alamatKaryawan"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group label="Status Pernikahan" label-cols-md="3">
            <b-form-select
              :options="statusPerkawinan"
              v-model="data.statusKaryawan"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Status Karyawan" label-cols-md="3">
            <b-form-select
              :options="status"
              v-model="data.statusKerjaKaryawan"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Tanggal Mulai Kerja" label-cols-md="3">
            <date-picker
              style="width: 100%"
              v-model="data.tanggalMulaiKerja"
              :placeholder="moment(data.tanggalMulaiKerja).format('LL')"
            ></date-picker>
          </b-form-group>

          <b-form-group label="Tanggal Gajian" label-cols-md="3">
            <b-form-input
              disabled
              type="number"
              v-model="data.tanggalGaji"
              placeholder="Silahkan Isi dari 1 sampai 31"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="No. Rekening" label-cols-md="3">
            <b-form-input
              type="number"
              v-model="data.nomorRekening"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Nama Bank" label-cols-md="3">
            <b-form-input type="text" v-model="data.namaBank"></b-form-input>
          </b-form-group>

          <b-form-group label="Lama Kontrak (Bulan)" label-cols-md="3">
            <b-form-select
              :options="bulan"
              v-model="data.lamaKontrakKaryawan"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="No. BPJS Ketenagakerjaan" label-cols-md="3">
            <b-form-input
              v-model="data.nomorBpjsKetenagakerjaan"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="No. BPJS Kesehatan" label-cols-md="3">
            <b-form-input v-model="data.nomorBpjsKesehatan"></b-form-input>
          </b-form-group>
          <b-button
            variant="primary"
            @click="simpanIdentitasDiri()"
            :disabled="busy"
          >
            {{ button }}
          </b-button>
          <b-alert
            dismissible
            fade
            :show="showing"
            :variant="variant"
            style="margin-top: 10px"
            >{{ msg }}</b-alert
          >
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "moment/locale/id";
import "vue2-datepicker/index.css";

moment.locale("id");
export default {
  props: ["data"],
  components: {
    DatePicker,
  },
  name: "tabIdentitasDiri",
  props: ["data"],
  data() {
    return {
      moment,
      username: "",
      password: "",
      showing: false,
      variant: "success",
      msg: "",
      button: "Simpan",
      busy: false,
      status: [
        { value: 0, text: "Kontrak" },
        { value: 1, text: "Permanen" },
      ],
      sim: [
        { value: "A", text: "A" },
        { value: "B1", text: "B1" },
        { value: "B2", text: "B2" },
        { value: "C", text: "C" },
        { value: "D", text: "D" },
        { value: "A Umum", text: "A Umum" },
        { value: "B Umum", text: "B Umum" },
        { value: "C Umum", text: "C Umum" },

      ],
      gol: [
        { value: "A", text: "A" },
        { value: "B", text: "B" },
        { value: "AB", text: "AB" },
        { value: "O", text: "O" },
      ],
      kebangsaan: [
        { value: "WNI", text: "WNI" },
        { value: "WNA", text: "WNA" },
      ],
      agama: [
        { value: "Islam", text: "Islam" },
        { value: "Katholik", text: "Katholik" },
        { value: "Protestan", text: "Protestan" },
        { value: "Hindu", text: "Hindu" },
        { value: "Budha", text: "Budha" },
        { value: "Khonghucu", text: "Khonghucu" },
      ],
      jenisKelamin: [
        { value: "Laki-laki", text: "Laki-laki" },
        { value: "Perempuan", text: "Perempuan" },
      ],
      statusPerkawinan: [
        { value: "Menikah", text: "Menikah" },
        { value: "Belum Menikah", text: "Belum Menikah" },
      ],
      bulan: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
        { value: 11, text: "11" },
        { value: 12, text: "12" },
      ],
    };
  },
  methods: {
    generate() {
      this.username = this.data.nomorKTPKaryawan;
      this.password = moment().format("ddHShms");
    },
    simpanIdentitasDiri() {
      let vm = this;
      // if (vm.data.tanggalGaji) {
      //   vm.data.tanggalGaji = parseInt(vm.data.tanggalGaji);
      //   console.log(vm.data.tanggalGaji);
      //   if (vm.data.tanggalGaji > 31 || vm.data.tanggalGaji < 1) {
      //     vm.variant = "danger";
      //     vm.msg = "TANGGAL GAJI HANYA BISA DI ISI 1 - 31";
      //     vm.showing = true;
      //     vm.triggerAlert();
      //   } else {
      //     vm.button = "Mohon Tunggu";
      //     vm.busy = true;
      //     console.log("sampe sini");
      //     if (vm.username && vm.password) {
      //       vm.data.username = vm.username;
      //       vm.data.password = vm.password;
      //       axios
      //         .post(ipBackend + "dataKaryawan/update", {
      //           dataKaryawanId: vm.data.dataKaryawanId,
      //           namaKaryawan: vm.data.namaKaryawan,
      //           nomorKTPKaryawan: vm.data.nomorKTPKaryawan,
      //           tinggiBadanKaryawan: vm.data.tinggiBadanKaryawan,
      //           beratBadanKaryawan: vm.data.beratBadanKaryawan,
      //           jenisKelaminKaryawan: vm.data.jenisKelaminKaryawan,
      //           statusKaryawan: vm.data.statusKaryawan,
      //           tempatLahirKaryawan: vm.data.tempatLahirKaryawan,
      //           tanggalLahirKaryawan: vm.data.tanggalLahirKaryawan,
      //           noHpKaryawan: vm.data.noHpKaryawan,
      //           emailKaryawan: vm.data.emailKaryawan,
      //           alamatKaryawan: vm.data.alamatKaryawan,
      //           tanggalMulaiKerja: vm.data.tanggalMulaiKerja,
      //           statusKerjaKaryawan: vm.data.statusKerjaKaryawan,
      //           lamaKontrakKaryawan: vm.data.lamaKontrakKaryawan,
      //           masterDivisiId: vm.data.masterDivisiId,
      //           NIK: vm.data.NIK,
      //           tanggalGaji: vm.data.tanggalGaji,
      //           namaBank: vm.data.namaBank,
      //           nomorRekening: vm.data.nomorRekening,
      //           nomorBpjsKetenagakerjaan: vm.data.nomorBpjsKetenagakerjaan,
      //           nomorBpjsKesehatan: vm.data.nomorBpjsKesehatan,
      //           username: vm.username,
      //           password: vm.password,
      //         })
      //         .then((res) => {
      //           console.log(res, "ini res");
      //           if (res.data.message == "sukses") {
      //             vm.busy = false;
      //             vm.button = "Simpan";
      //             vm.variant = "success";
      //             vm.msg = "BERHASIL MENGUBAH DATA PROFIL KARYAWAN";
      //             vm.showing = true;
      //             vm.triggerAlert();
      //           } else {
      //             vm.button = "Simpan";
      //             vm.busy = false;
      //             vm.variant = "danger";
      //             vm.msg = _.toUpper(res.data.message);
      //             vm.showing = true;
      //             vm.triggerAlert();
      //           }
      //           vm.username = "";
      //           vm.password = "";
      //         })
      //         .catch((err) => {
      //           vm.button = "Simpan";
      //           vm.busy = false;
      //           vm.$emit("alertFromChild", {
      //             variant: "danger",
      //             msg: "TERJADI KESALAHAN PADA SERVER",
      //             showing: true,
      //           });
      //         });
      //     } else {
      //       axios
      //         .post(ipBackend + "dataKaryawan/update", {
      //           dataKaryawanId: vm.data.dataKaryawanId,
      //           namaKaryawan: vm.data.namaKaryawan,
      //           nomorKTPKaryawan: vm.data.nomorKTPKaryawan,
      //           tinggiBadanKaryawan: vm.data.tinggiBadanKaryawan,
      //           beratBadanKaryawan: vm.data.beratBadanKaryawan,
      //           jenisKelaminKaryawan: vm.data.jenisKelaminKaryawan,
      //           statusKaryawan: vm.data.statusKaryawan,
      //           tempatLahirKaryawan: vm.data.tempatLahirKaryawan,
      //           tanggalLahirKaryawan: vm.data.tanggalLahirKaryawan,
      //           noHpKaryawan: vm.data.noHpKaryawan,
      //           emailKaryawan: vm.data.emailKaryawan,
      //           alamatKaryawan: vm.data.alamatKaryawan,
      //           tanggalMulaiKerja: vm.data.tanggalMulaiKerja,
      //           statusKerjaKaryawan: vm.data.statusKerjaKaryawan,
      //           lamaKontrakKaryawan: vm.data.lamaKontrakKaryawan,
      //           tanggalGaji: vm.data.tanggalGaji,
      //           namaBank: vm.data.namaBank,
      //           nomorRekening: vm.data.nomorRekening,
      //           masterDivisiId: vm.data.masterDivisiId,
      //           NIK: vm.data.NIK,
      //         })
      //         .then((res) => {
      //           console.log(res, "ini res");
      //           if (res.data.message == "sukses") {
      //             vm.busy = false;
      //             vm.button = "Simpan";
      //             vm.variant = "success";
      //             vm.msg = "BERHASIL MENGUBAH DATA PROFIL KARYAWAN";
      //             vm.showing = true;
      //             vm.triggerAlert();
      //           } else {
      //             vm.button = "Simpan";
      //             vm.busy = false;
      //             vm.variant = "danger";
      //             vm.msg = _.toUpper(res.data.message);
      //             vm.showing = true;
      //             vm.triggerAlert();
      //           }
      //         })
      //         .catch((err) => {
      //           vm.button = "Simpan";
      //           vm.busy = false;
      //           vm.$emit("alertFromChild", {
      //             variant: "danger",
      //             msg: "TERJADI KESALAHAN PADA SERVER",
      //             showing: true,
      //           });
      //         });
      //     }
      //   }
      // } else {
      //   vm.button = "Mohon Tunggu";
      //   vm.busy = true;
      //   // console.log(vm.data);
      //   if (vm.username && vm.password) {
      //     vm.data.username = vm.username;
      //     vm.data.password = vm.password;
      //     axios
      //       .post(ipBackend + "dataKaryawan/update", {
      //         dataKaryawanId: vm.data.dataKaryawanId,
      //         namaKaryawan: vm.data.namaKaryawan,
      //         nomorKTPKaryawan: vm.data.nomorKTPKaryawan,
      //         tinggiBadanKaryawan: vm.data.tinggiBadanKaryawan,
      //         beratBadanKaryawan: vm.data.beratBadanKaryawan,
      //         jenisKelaminKaryawan: vm.data.jenisKelaminKaryawan,
      //         statusKaryawan: vm.data.statusKaryawan,
      //         tempatLahirKaryawan: vm.data.tempatLahirKaryawan,
      //         tanggalLahirKaryawan: vm.data.tanggalLahirKaryawan,
      //         noHpKaryawan: vm.data.noHpKaryawan,
      //         emailKaryawan: vm.data.emailKaryawan,
      //         alamatKaryawan: vm.data.alamatKaryawan,
      //         tanggalMulaiKerja: vm.data.tanggalMulaiKerja,
      //         statusKerjaKaryawan: vm.data.statusKerjaKaryawan,
      //         lamaKontrakKaryawan: vm.data.lamaKontrakKaryawan,
      //         masterDivisiId: vm.data.masterDivisiId,
      //         NIK: vm.data.NIK,
      //         tanggalGaji: vm.data.tanggalGaji,
      //         namaBank: vm.data.namaBank,
      //         nomorRekening: vm.data.nomorRekening,
      //         username: vm.username,
      //         password: vm.password,
      //       })
      //       .then((res) => {
      //         if (res.data.message == "sukses") {
      //           vm.busy = false;
      //           vm.button = "Simpan";
      //           vm.variant = "success";
      //           vm.msg = "BERHASIL MENGUBAH DATA PROFIL KARYAWAN";
      //           vm.showing = true;
      //           vm.triggerAlert();
      //         } else {
      //           vm.button = "Simpan";
      //           vm.busy = false;
      //           vm.variant = "danger";
      //           vm.msg = _.toUpper(res.data.message);
      //           vm.showing = true;
      //           vm.triggerAlert();
      //         }
      //         vm.username = "";
      //         vm.password = "";
      //       })
      //       .catch((err) => {
      //         vm.button = "Simpan";
      //         vm.busy = false;
      //         vm.$emit("alertFromChild", {
      //           variant: "danger",
      //           msg: "TERJADI KESALAHAN PADA SERVER",
      //           showing: true,
      //         });
      //       });
      //   } else {
      //     axios
      //       .post(ipBackend + "dataKaryawan/update", {
      //         dataKaryawanId: vm.data.dataKaryawanId,
      //         namaKaryawan: vm.data.namaKaryawan,
      //         nomorKTPKaryawan: vm.data.nomorKTPKaryawan,
      //         tinggiBadanKaryawan: vm.data.tinggiBadanKaryawan,
      //         beratBadanKaryawan: vm.data.beratBadanKaryawan,
      //         jenisKelaminKaryawan: vm.data.jenisKelaminKaryawan,
      //         statusKaryawan: vm.data.statusKaryawan,
      //         tempatLahirKaryawan: vm.data.tempatLahirKaryawan,
      //         tanggalLahirKaryawan: vm.data.tanggalLahirKaryawan,
      //         noHpKaryawan: vm.data.noHpKaryawan,
      //         emailKaryawan: vm.data.emailKaryawan,
      //         alamatKaryawan: vm.data.alamatKaryawan,
      //         tanggalMulaiKerja: vm.data.tanggalMulaiKerja,
      //         statusKerjaKaryawan: vm.data.statusKerjaKaryawan,
      //         lamaKontrakKaryawan: vm.data.lamaKontrakKaryawan,
      //         tanggalGaji: vm.data.tanggalGaji,
      //         masterDivisiId: vm.data.masterDivisiId,
      //         namaBank: vm.data.namaBank,
      //         nomorRekening: vm.data.nomorRekening,
      //         NIK: vm.data.NIK,
      //       })
      //       .then((res) => {
      //         if (res.data.message == "sukses") {
      //           vm.busy = false;
      //           vm.button = "Simpan";
      //           vm.variant = "success";
      //           vm.msg = "BERHASIL MENGUBAH DATA PROFIL KARYAWAN";
      //           vm.showing = true;
      //           vm.triggerAlert();
      //         } else {
      //           vm.button = "Simpan";
      //           vm.busy = false;
      //           vm.variant = "danger";
      //           vm.msg = _.toUpper(res.data.message);
      //           vm.showing = true;
      //           vm.triggerAlert();
      //         }
      //       })
      //       .catch((err) => {
      //         vm.button = "Simpan";
      //         vm.busy = false;
      //         vm.$emit("alertFromChild", {
      //           variant: "danger",
      //           msg: "TERJADI KESALAHAN PADA SERVER",
      //           showing: true,
      //         });
      //       });
      //   }
      // }
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      // console.log(vm.data);
      if (vm.username && vm.password) {
        vm.data.username = vm.username;
        vm.data.password = vm.password;
        axios
          .post(ipBackend + "dataKaryawan/update", {
            dataKaryawanId: vm.data.dataKaryawanId,
            namaKaryawan: vm.data.namaKaryawan,
            agamaKaryawan: vm.data.agamaKaryawan,
            kebangsaanKaryawan: vm.data.kebangsaanKaryawan,
            nomorKTPKaryawan: vm.data.nomorKTPKaryawan,
            tinggiBadanKaryawan: vm.data.tinggiBadanKaryawan,
            beratBadanKaryawan: vm.data.beratBadanKaryawan,
            jenisKelaminKaryawan: vm.data.jenisKelaminKaryawan,
            statusKaryawan: vm.data.statusKaryawan,
            tempatLahirKaryawan: vm.data.tempatLahirKaryawan,
            tanggalLahirKaryawan: vm.data.tanggalLahirKaryawan,
            noHpKaryawan: vm.data.noHpKaryawan,
            emailKaryawan: vm.data.emailKaryawan,
            alamatKaryawan: vm.data.alamatKaryawan,
            tanggalMulaiKerja: vm.data.tanggalMulaiKerja,
            statusKerjaKaryawan: vm.data.statusKerjaKaryawan,
            lamaKontrakKaryawan: vm.data.lamaKontrakKaryawan,
            masterDivisiId: vm.data.masterDivisiId,
            NIK: vm.data.NIK,
            SIM: vm.data.SIM,
            golonganDarahKaryawan: vm.data.golonganDarahKaryawan,
            username: vm.username,
            password: vm.password,
            nomorBpjsKetenagakerjaan: vm.data.nomorBpjsKetenagakerjaan,
            nomorBpjsKesehatan: vm.data.nomorBpjsKesehatan,
            namaBank: vm.data.namaBank,
            kotaDomisili: vm.data.kotaDomisili,
            nomorRekening: vm.data.nomorRekening,
          })
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.busy = false;
              vm.button = "Simpan";
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH DATA PROFIL KARYAWAN",
                showing: true,
              });
              console.log("tes1");
              // vm.variant = "success";
              // vm.msg = "BERHASIL MENGUBAH DATA PROFIL KARYAWAN";
              // vm.showing = true;
              // vm.triggerAlert();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
              // vm.variant = "danger";
              // vm.msg = _.toUpper(res.data.message);
              // vm.showing = true;
              // vm.triggerAlert();
            }
            vm.username = "";
            vm.password = "";
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      } else {
        axios
          .post(ipBackend + "dataKaryawan/update", {
            dataKaryawanId: vm.data.dataKaryawanId,
            namaKaryawan: vm.data.namaKaryawan,
            agamaKaryawan: vm.data.agamaKaryawan,
            kebangsaanKaryawan: vm.data.kebangsaanKaryawan,
            nomorKTPKaryawan: vm.data.nomorKTPKaryawan,
            tinggiBadanKaryawan: vm.data.tinggiBadanKaryawan,
            beratBadanKaryawan: vm.data.beratBadanKaryawan,
            jenisKelaminKaryawan: vm.data.jenisKelaminKaryawan,
            statusKaryawan: vm.data.statusKaryawan,
            tempatLahirKaryawan: vm.data.tempatLahirKaryawan,
            tanggalLahirKaryawan: vm.data.tanggalLahirKaryawan,
            noHpKaryawan: vm.data.noHpKaryawan,
            emailKaryawan: vm.data.emailKaryawan,
            alamatKaryawan: vm.data.alamatKaryawan,
            tanggalMulaiKerja: vm.data.tanggalMulaiKerja,
            statusKerjaKaryawan: vm.data.statusKerjaKaryawan,
            lamaKontrakKaryawan: vm.data.lamaKontrakKaryawan,
            masterDivisiId: vm.data.masterDivisiId,
            NIK: vm.data.NIK,
            SIM: vm.data.SIM,
            golonganDarahKaryawan: vm.data.golonganDarahKaryawan,
            nomorBpjsKetenagakerjaan: vm.data.nomorBpjsKetenagakerjaan,
            nomorBpjsKesehatan: vm.data.nomorBpjsKesehatan,
            namaBank: vm.data.namaBank,
            kotaDomisili: vm.data.kotaDomisili,
            nomorRekening: vm.data.nomorRekening,
          })
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.busy = false;
              vm.button = "Simpan";
              // vm.variant = "success";
              // vm.msg = "BERHASIL MENGUBAH DATA PROFIL KARYAWAN";
              // vm.showing = true;
              // vm.triggerAlert();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH DATA PROFIL KARYAWAN",
                showing: true,
              });
              console.log("tes2");
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              // vm.variant = "danger";
              // vm.msg = _.toUpper(res.data.message);
              // vm.showing = true;
              // vm.triggerAlert();
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    // triggerAlert() {
    //   let vm = this;
    //   setTimeout(() => {
    //     vm.showing = false;
    //   }, 4000);
    // },
  },
};
</script>

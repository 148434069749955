<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card header-tag="header" header-bg-variant="dark">
          <!-- <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ $store.state.table.karyawan }}</strong>
              </h5>
            </template> -->

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Mitra"
                label-cols-md="2"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <multiselect
                  v-model="mitraId"
                  :options="mitra"
                  @input="getKoordinator(mitraId)"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="namaMitra"
                  placeholder="-- Pilih --"
                ></multiselect>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Koordinator"
                label-cols-md="2"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <multiselect
                  v-model="koordinatorId"
                  :options="koordinator"
                  @input="getCabang(koordinatorId)"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="namaKoordinator"
                  placeholder="-- Pilih --"
                ></multiselect>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cabang"
                label-cols-md="2"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <multiselect
                  v-model="cabangId"
                  :options="cabang"
                  @input="getCapem(cabangId)"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="namaCabang"
                  placeholder="-- Pilih --"
                ></multiselect>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Capem"
                label-cols-md="2"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <multiselect
                  v-model="capemId"
                  :options="capem"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="namaCapem"
                  placeholder="-- Pilih --"
                ></multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Divisi"
                label-cols-md="5"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <multiselect
                  v-model="divisiId"
                  :options="divisi"
                  @input="getPosisi(divisiId)"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="namaDivisi"
                  placeholder="-- Pilih --"
                ></multiselect>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Posisi"
                label-cols-md="5"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <multiselect
                  v-model="posisiId"
                  :options="posisi"
                  @input="itikiwir(posisiId)"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="namaPosisi"
                  placeholder="-- Pilih --"
                ></multiselect>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Status Kerja"
                label-cols-md="5"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  v-model="statusKerjaKaryawan"
                  :options="optionStatusKerja"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Jenis Kelamin"
                label-cols-md="5"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  v-model="jenisKelamin"
                  :options="listKelamin"
                  placeholder="-- Pilih --"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2"> <b-button
                  block
                  @click="printList()"
                  style="margin-top: 10px"
                  variant="light"
                  >Cetak List</b-button
                ></b-col> -->
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Usia"
                label-cols-md="4"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-input-group>
                  <b-form-input
                    md="3"
                    type="text"
                    placeholder="min"
                    style="margin-right: 5px"
                    v-model="umurMinimal"
                  ></b-form-input>
                  <b-form-input
                    md="3"
                    type="text"
                    placeholder="max"
                    v-model="umurMaksimal"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Masa Kerja"
                label-cols-md="4"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-input-group>
                  <b-form-input
                    md="2"
                    type="text"
                    placeholder="min (tahun)"
                    style="margin-right: 5px"
                    v-model="masaKerjaMinimal"
                  ></b-form-input>
                  <b-form-input
                    md="2"
                    type="text"
                    placeholder="max (tahun)"
                    v-model="masaKerjaMaximal"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Pendidikan Terakhir"
                label-cols-md="5"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  v-model="pendidikanTerakhir"
                  :options="optionPendidikanTerakhir"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-button
                block
                @click="filterring()"
                style="margin-top: 10px"
                variant="primary"
                >Simpan</b-button
              >
            </b-col>
            <b-col md="4">
              <b-button
                block
                @click="reset()"
                style="margin-top: 10px"
                variant="light"
                >Reset</b-button
              >
            </b-col>
            <b-col md="4">
              <b-button
                block
                @click="printList()"
                style="margin-top: 10px"
                variant="light"
                >Cetak List</b-button
              ></b-col
            >
          </b-row>
          <b-row style="margin-top: 15px">
            <b-col md="6" offset-md="6">
              <b-form-group
                :label="$store.state.table.cari"
                label-for="filter-input"
                label-cols-md="3"
                label-align-md="right"
                label-size="md"
              >
                <b-input-group size="md">
                  <b-form-input
                    id="filter-input"
                    v-model.lazy="filter"
                    type="search"
                    :placeholder="$store.state.table.cariHolder"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                      variant="danger"
                      >{{ $store.state.table.hapusCari }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-alert dismissible fade :show="showing" :variant="variant">{{
            msg
          }}</b-alert>
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                stacked="md"
                responsive
                show-empty
                small
                @filtered="onFiltered"
                bordered
                striped
                hover
                :busy="tableBusy"
              >
                <template #cell(actions)="item">
                  <b-button
                    variant="warning"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Edit Data'"
                    @click="editDataSudah(item.item)"
                    ><CIcon name="cil-pencil" />{{ item.actions }}</b-button
                  >

                  <b-button
                    variant="info"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Detail'"
                    v-b-modal.modal-detail-sudah
                    @click="(data = item.item), (detail = !detail)"
                    ><CIcon name="cil-info" />{{ item.actions }}</b-button
                  >

                  <!-- <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus / Pecat Karyawan'"
                      v-b-modal.modal-delete-sudah
                      @click="data = item.item"
                      ><CIcon name="cil-trash" />{{ item.actions }}</b-button
                    > -->

                  <b-button
                    variant="secondary"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Print Data Diri'"
                    @click="setPrint(item.item)"
                    ><CIcon name="cil-print" />{{ item.actions }}</b-button
                  >

                  <!-- <b-button
                      variant="secondary"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Print Surat Pernyataan'"
                      @click="printPernyataan(item.item)"
                      ><CIcon name="cil-print" />{{ item.actions }}</b-button
                    > -->
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-group
                :label="$store.state.table.perHalaman"
                label-for="per-page-select"
                label-cols-md="6"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3"> Total Data {{ totalRows }} </b-col>
            <b-col md="5" offset-md="4">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- <modal-edit
      :data="data"
      :edit="edit"
      :asuransi="asuransi"
      @alertFromChild="triggerAlert($event)"
    /> -->
    <modal-detail :data="data" :detail="detail" />
    <!-- <modal-delete
      :data="data"
      :pecat="pecat"
      @alertFromChild="triggerAlert($event)"
    /> -->
    <modal-keluarga />
    <modalPrint :printData="printData" />

    <modalDataKaryawan
      :data="data"
      :edit="edit"
      :asuransi="asuransi"
      @alertFromChild="triggerAlert($event)"
    />
  </b-container>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import modalKeluarga from "../../../component/hcms/karyawan/modalKeluarga.vue";
import moment from "moment";
moment.locale("id");
import modalDetail from "../../../component/hcms/karyawan/sudah/modalDetailSudah.vue";
import modalPrint from "../../../component/hcms/karyawan/sudah/modalPrint.vue";
import modalDelete from "../../../component/hcms/karyawan/sudah/modalDeleteSudah.vue";
import modalDataKaryawan from "../../../component/hcms/karyawan/sudah/modalDataKaryawanSudah.vue";
import Multiselect from "vue-multiselect";
export default {
  components: {
    modalDetail,
    modalDelete,
    modalKeluarga,
    modalDataKaryawan,
    Multiselect,
    modalPrint,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      asuransi: [],
      koordinator: [],
      cabang: [],
      capem: [],
      mitra: [],
      posisi: [],
      divisi: [],
      mitraId: "",
      umurMinimal: "",
      umurMaksimal: "",
      cabangId: "",
      posisiId: "",
      divisiId: "",
      koordinatorId: "",
      capemId: "",
      jenisKelamin: null,
      masaKerjaMinimal: null,
      masaKerjaMaximal: null,
      listKelamin: [
        { value: null, text: "Silahkan Pilih" },
        { value: "Laki-laki", text: "Laki-laki" },
        { value: "Perempuan", text: "Perempuan" },
      ],
      statusKerjaKaryawan: null,
      optionStatusKerja: [
        { value: null, text: "Silahkan Pilih" },
        { value: 0, text: "Kontrak" },
        { value: 1, text: "Permanen" },
      ],
      pendidikanTerakhir: null,
      optionPendidikanTerakhir: [
        { value: null, text: "Silahkan Pilih" },
        { value: "SD", text: "SD" },
        { value: "SMP", text: "SMP" },
        { value: "MTs", text: "MTs" },
        { value: "SMA", text: "SMA" },
        { value: "SMK", text: "SMK" },
        { value: "MA", text: "MA" },
        { value: "D-1", text: "D-1" },
        { value: "D-2", text: "D-2" },
        { value: "D-3", text: "D-3" },
        { value: "D-4", text: "D-4" },
        { value: "S-1", text: "S-1" },
        { value: "S-2", text: "S-2" },
        { value: "Profesi", text: "Profesi" },
        { value: "Kejar Paket C", text: "Kerjar Paket C" },
      ],
      msg: "",
      data: "",
      printData: "",
      edit: false,
      detail: false,
      pecat: [],
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "NIP",
          label: "NIK",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatKaryawan",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "penempatan",
          label: "Penempatan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaDivisi",
          label: "Divisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaPosisi",
          label: "Posisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-5 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["namaKaryawan", "penempatan"],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
    this.filterring();
  },
  methods: {
    editDataSudah(data) {
      let vm = this;
      vm.data = data;
      vm.edit = !vm.edit;

      if (vm.data != "") {
        vm.$bvModal.show("modal-data-karyawan-sudah");
      }
    },
    async setPrint(x) {
      console.log(x);
      let vm = this;
      let print = await axios.get(
        ipBackend + "dataKaryawan/coverDataDiri/" + x.dataKaryawanId
      );
      console.log(print.data.data[0], "iii");
      let y = print.data.data[0];
      let jmlAnak = 0;
      if (y.pengalamanKerja.length) {
        let z = y.pengalamanKerja.length - 1;
        y.pengalamanPrint =
          "sebagai " +
          y.pengalamanKerja[z].jabatan +
          " di " +
          y.pengalamanKerja[z].namaPerusahaan +
          " " +
          y.pengalamanKerja[z].tahun +
          " tahun";
      }
      y.keluarga.forEach((el) => {
        if (
          el.statusDalamKeluarga == "Istri" ||
          el.statusDalamKeluarga == "Suami"
        ) {
          y.pasangan = el.nama;
          y.tanggalPasangan = moment(el.tanggalLahir).format("LL");
        } else if (el.statusDalamKeluarga == "Anak") {
          if (jmlAnak < 3) {
            jmlAnak++;
            y[`anak${jmlAnak}`] = el.nama;
            y[`tanggalAnak${jmlAnak}`] = moment(el.tanggalLahir).format("LL");
          }
        }
      });
      if (print) y.tanggal = moment().format("LL");
      y.tanggalMulaiKerjaPrint = moment(y.tanggalMulaiKerja).format("LL");
      y.tanggalLahirKaryawanPrint = moment(y.tanggalLahirKaryawan).format("LL");
      if (y.statusKerjaKaryawan == 0) {
        y.statusKerjaKaryawanPrint = "KONTRAK";
      } else {
        y.statusKerjaKaryawanPrint = "TETAP";
      }
      if (y.statusKaryawan) {
        y.statusKaryawan = y.statusKaryawan.toUpperCase();
      }
      vm.printData = y;
      vm.$bvModal.show("modal-print");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getKoordinator(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      //   console.log(koordinator.data.data, "ini koordinator");
      this.koordinator = koordinator.data.data;
      this.koordinatorId = "";
      this.cabangId = "";
      this.capemId = "";
    },
    async getCabang(x) {
      //   console.log(x, "ini korid");
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.cabang = cabang.data.data;
      this.cabangId = "";
      this.capemId = "";
    },
    async getCapem(x) {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.capem = capem.data.data;
      this.capemId = "";
    },
    async getPosisi(x) {
      let listPosisi = await axios.get(
        ipBackend + "masterPosisi/listPosisiByMasterDivisiId/" + x.id
      );
      this.posisi = listPosisi.data.data;
      this.posisiId = "";
    },
    async getDatas() {
      this.items = [];
      this.tableBusy = true;
      // let listKaryawan = await axios.get(ipBackend + "dataKaryawan/list");
      //   this.items = listKaryawan.data.data;
      let listMitra = await axios.get(ipBackend + "mitra/list");
      this.mitra = listMitra.data.data;
      let listAsuransi = await axios.get(ipBackend + "masterAsuransi/list");
      this.asuransi = listAsuransi.data.data;
      let listPecat = await axios.get(
        ipBackend + "masterKeteranganKaryawanKeluar/list"
      );
      this.pecat = listPecat.data.data;

      let listDivisi = await axios.get(ipBackend + "masterDivisi/list");
      this.divisi = listDivisi.data.data;
      // FOREACH diganti FOR
      // this.items.forEach((element, index) => {
      //   let x = this.items[index];
      //   x.no = index + 1;
      //   x.tglMulai = moment(x.tanggalMulaiKerja).format("LL");
      //   x.tglLahir = moment(x.tanggalLahirKaryawan).format("LL");
      // });
      // for (let i = 0; i < listKaryawan.data.data.length; i++) {
      //   let x = listKaryawan.data.data[i];
      //   if (x.statusPenempatan == 1) {
      //     this.items.push(x);
      //   }
      // }
      // for (let i = 0; i < this.items.length; i++) {
      //   let x = this.items[i];
      //   x.no = i + 1;
      //   x.tglLahir = moment(x.tanggalLahirKaryawan).format("LL");
      //   if (!x.tanggalMulaiKerja) {
      //     x.tglMulai = "-";
      //   } else {
      //     x.tglMulai = moment(x.tanggalMulaiKerja).format("LL");
      //   }
      // }
      // console.log(this.items, "ini item");
      this.totalRows = this.items.length;
      this.tableBusy = false;
    },
    reset() {
      let vm = this;
      vm.mitraId = "";
      vm.koordinatorId = "";
      vm.cabangId = "";
      vm.capemId = "";
      vm.umurMinimal = "";
      vm.umurMaksimal = "";
      vm.masaKerjaMinimal = "";
      vm.masaKerjaMaximal = "";
      vm.statusKerjaKaryawan = null;
      vm.jenisKelamin = null;
      vm.pendidikanTerakhir = null;
      vm.posisiId = "";
      vm.divisiId = "";
      vm.getDatas();
    },
    itikiwir(x) {
      console.log(x);
    },
    itikiwir2(x) {
      console.log(x);
    },
    filterring() {
      let vm = this;
      let dataFilter = {};
      if (vm.mitraId) {
        dataFilter.namaMitra = vm.mitraId.namaMitra;
      } else {
        dataFilter.namaMitra = "";
      }
      if (vm.koordinatorId) {
        dataFilter.namaKoordinator = vm.koordinatorId.namaKoordinator;
      } else {
        dataFilter.namaKoordinator = "";
      }
      if (vm.cabangId) {
        dataFilter.namaCabang = vm.cabangId.namaCabang;
      } else {
        dataFilter.namaCabang = "";
      }
      if (vm.capemId) {
        dataFilter.namaCapem = vm.capemId.namaCapem;
      } else {
        dataFilter.namaCapem = "";
      }
      dataFilter.namaDivisi = vm.divisiId.namaDivisi;
      dataFilter.namaPosisi = vm.posisiId.namaPosisi;
      dataFilter.jenisKelamin = vm.jenisKelamin;
      dataFilter.statusKerjaKaryawan = vm.statusKerjaKaryawan;
      dataFilter.umurMinimal = parseInt(vm.umurMinimal);
      dataFilter.umurMaksimal = parseInt(vm.umurMaksimal);
      dataFilter.masaKerjaMinimal = parseInt(vm.masaKerjaMinimal);
      dataFilter.masaKerjaMaximal = parseInt(vm.masaKerjaMaximal);
      dataFilter.pendidikanTerakhir = vm.pendidikanTerakhir;
      console.log(dataFilter, "ini datanya");
      axios
        .post(ipBackend + "dataKaryawan/filterDataKaryawan", dataFilter)
        .then((res) => {
          // console.log(res, "ini res");
          this.items = res.data.data;
          for (let i = 0; i < this.items.length; i++) {
            let x = this.items[i];
            x.no = i + 1;
            x.tglMulai = moment(x.tanggalMulaiKerja).format("LL");
            x.tglLahir = moment(x.tanggalLahirKaryawan).format("LL");
          }
          this.totalRows = this.items.length;
          // console.log(this.items, "ini item");
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    triggerAlert(event) {
      let vm = this;
      // console.log("karyawan sudah");
      vm.$store.commit("set_alert", event);
      vm.filterring();
    },
    // triggerAlert(event) {
    //   let vm = this;
    //   vm.showing = event.showing;
    //   vm.variant = event.variant;
    //   vm.msg = event.msg;
    //   vm.getDatas();
    //   setTimeout(() => {
    //     vm.showing = false;
    //   }, 4000);
    // },
    printDataDiri(data) {
      window.open(
        ipBackend + "dataKaryawan/coverDataDiri/" + data.dataKaryawanId,
        "_blank"
      );
    },
    printPernyataan(data) {
      window.open(
        ipBackend + "dataKaryawan/suratPernyataan/" + data.dataKaryawanId,
        "_blank"
      );
    },
    printList() {
      let vm = this;
      let query = "?";

      if (vm.mitraId) {
        query += `mitraId=${vm.mitraId.id}&`;
      }
      if (vm.koordinatorId) {
        query += `koordinatorId=${vm.koordinatorId.id}&`;
      }
      if (vm.cabangId) {
        query += `cabangId=${vm.cabangId.id}&`;
      }
      if (vm.capemId) {
        query += `capemId=${vm.capemId.id}&`;
      }
      if (vm.umurMinimal) {
        query += `umurMinimal=${vm.umurMinimal}&`;
      }
      if (vm.umurMaksimal) {
        query += `umurMaksimal=${vm.umurMaksimal}&`;
      }
      if (vm.statusKerjaKaryawan) {
        query += `statusKerjaKaryawan=${vm.statusKerjaKaryawan}&`;
      }
      if (vm.jenisKelamin) {
        query += `jenisKelamin=${vm.jenisKelamin}&`;
      }
      if (vm.posisiId) {
        query += `masterPosisiId=${vm.posisiId.id}&`;
      }
      if (vm.divisiId) {
        query += `masterDivisiId=${vm.divisiId.id}&`;
      }
      // console.log(query, "queryss");
      window.open(
        ipBackend + `dataKaryawan/cetakDataKaryawan/` + query,
        "_blank"
      );
    },
  },
};
</script>

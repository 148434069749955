<template>
  <div>
    <b-modal
      id="modal-edit-lampiran"
      size="lg"
      centered
      title="Edit Data Dokumen/Lampiran"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Dokumen/Lampiran" label-cols-md="3">
          <b-form-select :options="lampiran"></b-form-select>
        </b-form-group>

        <b-form-group label="Upload File" label-cols-md="3">
          <b-form-file plain></b-form-file>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-lampiran')"
        >
          {{ $store.state.form.batal }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lampiran: [
        { value: "", text: "Lampiran 1" },
        { value: "", text: "Lampiran 2" },
      ],
    };
  },
};
</script>

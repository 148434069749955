<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <h4><strong>Data Dokumen/Lampiran</strong></h4>
        <hr />
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-button variant="primary" v-b-modal.modal-input-lampiran
          ><CIcon name="cil-plus" /> Tambah Data</b-button
        >
      </b-col>
    </b-row> -->
    <b-row class="mt-3">
      <b-col md="3">
        <b-form-group
          :label="$store.state.table.perHalaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          :label="$store.state.table.cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$store.state.table.cariHolder"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >{{ $store.state.table.hapusCari }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(fileKebutuhan)="item">
            <b-button
              variant="success"
              size="sm"
              v-if="item.item.fileKebutuhan != null"
              @click="view(item.item.fileKebutuhan)"
              >Lihat File</b-button
            >

            <b-button v-else variant="danger" size="sm"
              >Tidak Ada File</b-button
            >
          </template>
          <!-- <template #cell(actions)="item">
            <b-button
              variant="warning"
              class="mr-1"
              v-c-tooltip.hover.click="$store.state.table.tooltipEdit"
              v-b-modal.modal-edit-lampiran
              @click="data = item.item"
              ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
            >

            <b-button
              variant="danger"
              class="mr-1"
              v-c-tooltip.hover.click="$store.state.table.tooltipHapus"
              v-b-modal.modal-delete-lampiran
              @click="data = item.item"
              ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
            >
          </template> -->
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
    <modal-input />
    <modal-edit />
    <modal-delete />
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import modalInput from "../../../component/hcms/karyawan/lampiran/modalInput.vue";
import modalEdit from "../../../component/hcms/karyawan/lampiran/modalEdit.vue";
import modalDelete from "../../../component/hcms/karyawan/lampiran/modalDelete.vue";
export default {
  props: ["data"],
  components: {
    modalInput,
    modalEdit,
    modalDelete,
  },
  data() {
    return {
      items: [
        {
          no: "-",
          lampiran: "-",
          fileLampiran: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKebutuhan",
          label: "Lampiran",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "fileKebutuhan",
          label: "File Lampiran",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-3 text-center",
        // },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getDokumen();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDokumen() {
      let id = this.data.dataKaryawanId;
      let listDokumen = await axios.get(
        ipBackend + "dataKaryawan/detailsByDataKaryawanId/" + id
      );
      this.items = listDokumen.data.data[0].berkasFile;
      console.log(this.items, "ini this item");
    },
    view(x) {
      window.open(`${ipBackend}asset/pdf/${x}`);
    },
  },
};
</script>

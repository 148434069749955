<template>
  <div>
    <b-modal
      id="modal-detail"
      size="lg"
      centered
      scrollable
      title="Detail Karyawan"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-row>
        <b-col cols="12" md="4" lg="4">
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <div style="position: relative">
                <img
                  v-if="!src1"
                  src="https://via.placeholder.com/360"
                  alt=""
                  style="width: 100%"
                />
                <img v-else :src="src1" alt="" style="width: 100%" />
                <div
                  style="
                    width: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #3c4b64;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    border-bottom-left-radius: 15px;
                  "
                >
                  <h6 style="color::#fff" class="mt-0 mb-0">
                    <strong>FOTO PROFIL</strong>
                  </h6>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col cols="12" md="12" lg="12">
              <div style="position: relative">
                <img
                  v-if="!src2"
                  src="https://via.placeholder.com/360"
                  alt=""
                  style="width: 100%"
                />
                <img v-else :src="src2" alt="" style="width: 100%" />

                <div
                  style="
                    width: 65%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #3c4b64;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    border-bottom-left-radius: 15px;
                  "
                >
                  <h6 style="color::#fff" class="mt-0 mb-0">
                    <strong>KARTU KELUARGA</strong>
                  </h6>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="8" lg="8">
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <h4><strong>PROFIL</strong></h4>
              <hr />
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table-simple borderless small>
                <b-tbody>
                  <b-tr>
                    <b-td style="width: 35%">Nama</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.namaKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>No. KTP</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.nomorKTPKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Posisi</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.namaPosisi }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Divisi</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.namaDivisi }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Tinggi Badan</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.tinggiBadanKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Berat Badan</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.beratBadanKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Agama</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.agamaKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Kebangsaan</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.kebangsaanKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Jenis Kelamin</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.jenisKelaminKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Tempat Lahir</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.tempatLahirKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Tanggal Lahir</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.tglLahir }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>No. Telepon</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.noHpKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Email</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.emailKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Alamat</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.alamatKaryawan }}</b-td>
                  </b-tr>

                  <!-- <b-tr>
                    <b-td>BPJS Ketenagakerjaan</b-td>
                    <b-td>:</b-td>
                    <b-td></b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>BPJS Kesehatan</b-td>
                    <b-td>:</b-td>
                    <b-td></b-td>
                  </b-tr> -->

                  <b-tr>
                    <b-td>Status Pernikahan</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.statusKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Status Karyawan</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.statusKerja }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Tanggal Mulai Kerja</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.tglMulai }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Lama Kontrak</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.lamaKontrakKaryawan }} Bulan</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <h4><strong>KELUARGA</strong></h4>
              <hr />
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table-simple borderless small>
                <b-tbody>
                  <b-tr>
                    <b-td style="width: 35%">Nama Suami/Istri</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.namaIstriKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>No. KTP Suami/Istri</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.KTPIstriKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Alamat Suami/Istri</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.alamatIstriKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Jumlah Anak</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.jumlahAnakKaryawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Anak 1</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.namaAnak1Karyawan }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Anak 2</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.namaAnak2Karyawan }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ipBackend } from "@/ipBackend";
export default {
  name: "modalDetail",
  props: ["data", "detail"],
  data() {
    return {
      src1: "",
      src2: "",
    };
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        if (this.data.statusKerjaKaryawan == 0) {
          this.data.statusKerja = "Kontrak";
        } else if (this.data.statusKerjaKaryawan == 1) {
          this.data.statusKerja = "Permanen";
        }
        this.src1 = "";
        this.src2 = "";
        if (this.data.fotoProfilKaryawan != null) {
          this.src1 = `${ipBackend}asset/file/${this.data.fotoProfilKaryawan}`;
        }
        if (this.data.fotoKKKaryawan != null) {
          this.src2 = `${ipBackend}asset/file/${this.data.fotoKKKaryawan}`;
        }
      }
      // console.log(this.data);
    },
  },
};
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <h4><strong>Data Tunjangan</strong></h4>
        <hr />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form>
          <b-form-group label="Nama Tunjangan" label-cols-md="3">
            <b-form-select
              :options="tunTetap"
              v-model="dataTT.masterTunjanganId"
              @input="setNominal(dataTT.masterTunjanganId)"
            ></b-form-select>
          </b-form-group>
        </b-form>
        <b-form>
          <b-form-group label="Nominal Tunjangan" label-cols-md="3">
            <b-form-input v-model="dataTT.nominalTunjangan"></b-form-input>
          </b-form-group>
        </b-form>
        <b-form>
          <b-form-group label="Keterangan" label-cols-md="3">
            <b-form-textarea v-model="dataTT.keterangan"></b-form-textarea>
          </b-form-group>
        </b-form>

        <b-button variant="primary" @click="simpan()"
          ><CIcon name="cil-plus" /> Tambah Data</b-button
        >
      </b-col>
    </b-row>

    <b-alert dismissible fade :show="showing" :variant="variant">{{
      msg
    }}</b-alert>

    <b-row class="mt-3">
      <b-col md="3">
        <b-form-group
          :label="$store.state.table.perHalaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          :label="$store.state.table.cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$store.state.table.cariHolder"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >{{ $store.state.table.hapusCari }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(durasiTunjangan)="item">
            {{ item.item.durasiTunjangan }} {{item.item.jenisDurasiTunjangan}}
          </template>
          <template #cell(actions)="item">

            <b-button
              variant="danger"
              class="mr-1"
              v-c-tooltip.hover.click="$store.state.table.tooltipHapus"
              v-b-modal.modal-delete-tunj
              @click="dataTunjangan = item.item"
              ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
            >
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
    <modal-delete
      :dataTunjangan="dataTunjangan"
      @alert="triggerAlert($event), getDatas()"
    />
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import modalDelete from "../../../component/hcms/karyawan/tunjangan/modalDelete.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    DatePicker,
    modalDelete,
  },
  props: ["data"],
  data() {
    return {
      moment,
      jdt: ["bulan", "hari"],
      items: [
        {
          no: "-",
          tunjanganTetap: "-",
        },
      ],
      dataTunjangan: "",
      dataTT: {
        masterTunjanganId: "",
        nominalTunjangan: "",
        // durasiTunjangan: null,
        // jenisDurasiTunjangan: "bulan",
        // tglAwalTunjangan: "",
        keterangan: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaTunjangan",
          label: "Nama Tunjangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nominalTunjangan",
          label: "Nominal Tunjangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "keterangan",
          label: "Keterangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-3 text-center",
        },
      ],
      variant: "",
      msg: "",
      showing: false,
      tunTetap: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  created() {
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setNominal(x) {
      this.tunTetap.forEach((item) => {
        if (item.id == x) {
          this.dataTT.nominalTunjangan = item.nominalTunjangan;
        }
      });
    },
    async getDatas() {
      let vm = this;
      let tunTetap = await axios.get(ipBackend + "masterTunjangan/list");

      vm.tunTetap = tunTetap.data.data.map((item) => {
        return { ...item, value: item.id, text: item.namaTunjangan };
      });

      let tunKaryawan = await axios.post(
        ipBackend + "poolTunjangan/listPoolTunjanganByDataKaryawanId/",
        { dataKaryawanId: vm.data.dataKaryawanId }
      );
      // console.log(tunKaryawan.data.data,'tuntun');
      vm.items = tunKaryawan.data.data;
      this.totalRows = vm.items.length;
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.dataTT.dataKaryawanId = vm.data.dataKaryawanId;
      axios
        .post(ipBackend + "poolTunjangan/register", vm.dataTT)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.variant = "success";
            vm.msg = "BERHASIL MENDAFTARKAN TUNJANGAN";
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
            this.dataTT = {
              masterTunjanganTetapId: "",
              nominalTunjangan: "",
            };
            this.getDatas();
            vm.$emit("change");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.$emit("change");
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
};
</script>

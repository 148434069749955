<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <h4><strong>Data Riwayat Pendidikan</strong></h4>
        <hr />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-button variant="primary" v-b-modal.modal-input-pendidikan
          ><CIcon name="cil-plus" /> Tambah Data</b-button
        >
      </b-col>
    </b-row>
    <b-alert
      dismissible
      fade
      :show="showing"
      :variant="variant"
      style="margin-top: 10px"
      >{{ msg }}</b-alert
    >
    <b-row class="mt-3">
      <b-col md="3">
        <b-form-group
          :label="$store.state.table.perHalaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          :label="$store.state.table.cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$store.state.table.cariHolder"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >{{ $store.state.table.hapusCari }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="pendidikan"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(actions)="item">
            <!-- <b-button
              variant="warning"
              class="mr-1"
              v-c-tooltip.hover.click="$store.state.table.tooltipEdit"
              v-b-modal.modal-edit-pendidikan
              @click="data = item.item"
              ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
            > -->

            <b-button
              variant="danger"
              class="mr-1"
              v-c-tooltip.hover.click="$store.state.table.tooltipHapus"
              v-b-modal.modal-delete-pendidikan
              @click="dataPendidikan = item.item"
              ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
            >
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
    <modal-input :data="data" @alertFromChild="triggerAlert($event)" />
    <modal-edit
      :dataPendidikan="dataPendidikan"
      @alertFromChild="triggerAlert($event)"
    />
    <modal-delete
      :dataPendidikan="dataPendidikan"
      @alertFromChild="triggerAlert($event)"
    />
  </div>
</template>
<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import modalInput from "../../../component/hcms/karyawan/RiwayatPendidikan/modalInput.vue";
import modalEdit from "../../../component/hcms/karyawan/RiwayatPendidikan/modalEdit.vue";
import modalDelete from "../../../component/hcms/karyawan/RiwayatPendidikan/modalDelete.vue";
export default {
  props: ["data"],
  components: {
    modalInput,
    modalEdit,
    modalDelete,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      pendidikan: [],
      dataPendidikan: "",
      items: [
        {
          no: "-",
          tingkatPendidikan: "-",
          namaSekolah: "-",
          tahunKelulusan: "-",
          jurusan: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "tingkatPendidikan",
          label: "Tingkat Pendidikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaSekolah",
          label: "Nama Sekolah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tahunLulus",
          label: "Tahun Kelulusan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jurusan",
          label: "Jurusan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-3 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getPengalamanPendidikan();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getPengalamanPendidikan() {
      this.pendidikan = []
      let id = this.data.dataKaryawanId;
      let listPengpen = await axios.get(
        ipBackend + "dataKaryawan/detailsByDataKaryawanId/" + id
      );
      // console.log(listPengpen.data.data,'pendidikan')
      this.pendidikan = listPengpen.data.data[0].riwayatPendidikan;
      // console.log(this.pendidikan,'didik');
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getPengalamanPendidikan();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-edit-tunjtetap"
      size="lg"
      centered
      title="Edit Data Tunjangan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label=" Nama Tunjangan" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('namaTunjangan')"
            v-model="$v.data.namaTunjangan.$model"
            @keydown.enter.prevent="simpan()"
          ></b-form-input>
        </b-form-group>
      </b-form>

      <b-form>
        <b-form-group label="Nominal Tunjangan" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('nominalTunjangan')"
            v-model="$v.data.nominalTunjangan.$model"
            :placeholder="convert(data.nominalTunjangan)"
            @keydown.enter.prevent="simpan()"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-tunjtetap')"
        >
          {{ $store.state.form.batal }}
        </b-button>
        <b-button variant="primary" @click="simpan()" :disabled="!isValid"
          >Simpan</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  name: "modalInput",
  props: ["data"],
  data() {
    return {
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      namaTunjangan: {
        required,
      },
      nominalTunjangan: {
        required,
        numeric,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    convert(x) {
      if (x) {
        return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
    simpan() {
      let vm = this;
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        axios
          .post(ipBackend + "masterTunjangan/update", vm.data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alert", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN MASTER TUNJANGAN",
                showing: true,
              });
              this.$bvModal.hide("modal-edit-tunjtetap");
              this.reset();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alert", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    reset() {
      this.$v.$reset();
      this.data = {
        namaTunjangan: "",
        nominalTunjangan: "",
      };
    },
  },
};
</script>
